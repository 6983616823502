.provider-home {
   margin-left: auto;
   margin-right: auto;
}

.provider-home-search {
   padding: 15px 15px;
   margin-left: auto;
   margin-right: auto;
   display: flex;
   justify-content: flex-end;
}

.provider-home-content {
   overflow: hidden;
   height: var(--height);
   display: flex;
   flex-direction: column;
   min-height: 310px;
}

.provider-home .status-action {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   padding: 10px 0px;
}

.provider-home .divider {
   padding: 5px 0px;
   width: calc(100vw - 20px);
}

@media (max-width: 1000px) {
   .provider-home-content {
      height: calc(100vh - 195px);
      overflow: auto;
      margin: 0px 0px 10px 0px;
   }
   .provider-home .status-action {
      padding: 10px 10px;
   }
   .provider-home .search-result {
      /*height: calc(100vh - 200px);*/
      padding: 0px 17px;
      overflow: auto;
   }
}

@media (min-width: 1000px) {
   .provider-home {
      width: var(--main-width);
      min-height: 700px !important;
   }
   .provider-home-search {
      padding: 25px 0px 10px 0px;
   }
   .provider-home-content {
      padding: 10px 0px;
   }
   .provider-home .status {
      padding: 10px 0px;
   }
   .provider-home .action {
      padding: 0px 0px;
   }
}
