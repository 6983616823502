.it-work {
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   padding-bottom: 50px;
}

.it-work-content {
   position: relative;
   margin: auto;
   padding: 30px 50px 50px 50px !important;
   margin-bottom: 20px;
   overflow: hidden;
}

.it-work-line {
   border-top: 1px solid #daeff8;
   width: 800px;
   height: 100px;
   float: left;
   margin: 60px 100px 0px 100px;
}

.it-work-title {
   font-family: var(--font-family-bold);
   font-size: 20px;
   color: #444;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.it-work-description {
   font-weight: 600;
   font-size: 15px;
   color: #555;
   height: 30px;
   margin-bottom: 10px !important;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.it-work-items {
   background-color: transparent;
   padding: 20px 0px 10px 0px;
   float: left;
   text-align: center;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   overflow: hidden;
   z-index: 9;
   position: absolute;
}

@media (max-width: 1000px) {
   .it-work {
      padding: 0px 20px;
   }

   .it-work-content {
      padding: 25px 0px 0px 0px !important;
   }

   .it-work-line {
      display: none;
   }

   .it-work-description {
      margin-bottom: 30px;
   }
   .it-work-items {
      position: relative;
      margin-bottom: 10px !important;
      z-index: 0;
   }
}

@media (min-width: 1000px) {
   .it-work-content {
      width: 1100px !important;
   }
}
