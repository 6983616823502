.termsconditions {
   padding: 30px 50px;
   display: relative;
   margin-left: auto;
   margin-right: auto;
   max-width: 900px;
}

.termsconditions-wrapper {
   justify-content: center;
}

.termsconditions-header {
   padding: 0px 0px 10px 0px;
   text-align: center;
}

.termsconditions-desc {
   padding: 0px 0px 20px 0px;
}

.termsconditions .ant-collapse-header {
   font-size: 14px;
   color: #555 !important;
}

@media (max-width: 1000px) {
   .termsconditions {
      height: calc(100vh - 140px);
      width: 100%;
      padding: 20px 20px;
      margin: 10px 0px;
      overflow: auto;
   }
}
