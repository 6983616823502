.new-booking {
   width: 100%;
   height: 100%;

   z-index: 99;
   position: absolute;
   left: 0px;
   top: 0px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;

   background: rgb(0, 0, 0, 0.1);
}

.new-booking-container {
   width: var(--popup-width);
   /*height: calc(100vh - 180px);*/

   align-items: stretch;
   justify-content: center;
   border-radius: 3px;
   background-color: #fff;
   margin: auto;
   margin-top: auto;
   margin-bottom: auto;
}

.new-booking-content {
   padding: 0px 10px 10px 10px;
}

.new-booking-option {
   padding: 20px 10px;
   border: 1px dashed var(--dark-border-color);
   margin-bottom: 5px;
   display: flex;
   justify-content: space-between;
   cursor: pointer;
}

.new-booking-option .icon {
   font-size: 18px;
   color: var(--dark-border-color);
}

.new-booking input:hover,
.new-booking input:active {
   border: 0px solid #fff !important;
}

.new-booking-buttons {
   display: flex;
   bottom: 0px;
   padding-top: 25px;
   padding-bottom: 0px;
   background-color: #fff;
   /*padding-top: 10px;
   display: flex;
   flex-direction: row;
   ...Layout.padding-bottomContent;*/
}

@media (max-width: 1000px) {
   .new-booking-container {
      width: calc(100vw - 20px);
   }

   .new-booking-search {
      height: calc(100vh - 139px);
   }

   .new-booking-search-result {
      max-height: calc(100vh - 220px);
      padding-right: 0px;
   }

   .new-booking-content {
      padding: 20px 10px 10px 10px;
   }
}
