.provider-info-review-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  width: calc(100% / 2);
  border-radius: 6px;
  flex-basis: 50%;
}

.provider-info-review-img {
  position: relative;
  width: 50px;
  object-fit: contain;
  top: 0px;
}

.provider-info-review-profile {
  padding: 0px 15px;
}

.provider-info-review-content {
  flex: 1;
  padding: 0px 15px;
}

.provider-info-review-reviewer {
  display: flex;
  justify-content: space-between;
}

.provider-info-review-reviewer-content {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.provider-info-review-rating {
  padding-left: 5px;
}

.provider-info-review-remarks {
}

@media (max-width: 1000px) {
  .provider-info {
     height: calc(100vh - 80px) !important;
     min-height: calc(100vh - 80px) !important;
     overflow: auto;
     margin: 10px 0px;
  }

  .provider-info-content {
     padding: 0px 15px !important;
     margin: 0px 0px !important;
  }

  .provider-info-review-item {
     display: flex;
     flex-direction: row;
     width: calc(100%);
     flex-basis: 100%;
  }

  .provider-info-category-img {
     width: calc((100vw - 60px) / 4);
     height: calc((100vw - 60px) / 4);
     padding: 0px 0px;
  }
}

@media (max-width: 445px) {
  .provider-info .section-info-rating {
     flex-direction: column;
  }
}

@media (min-width: 1000px) {
  .provider-info {
     width: 1000px !important;
  }
}