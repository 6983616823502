.simple-button-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   cursor: pointer;
}

.simple-button-icon {
   display: flex;
   justify-content: center;
   text-align: center;
   padding: 2px 3px 3px 2px;
   margin-right: 3px;
   font-size: 13px;
   color: var(--font-color-green);
   /*...Font.fontSizeSubRegular;
  ...Font.fontColorGreen;*/
}

.simple-button-text {
   justify-content: center;
   text-align: center;
   font-size: 12px;
   color: #02b3ab;
   font-weight: 600;
   /*...Font.fontSizeSubRegular;
  ...Font.fontColorGreen;*/
}

@media (min-width: 1000px) {
   .simple-button-icon {
      padding: 0px 3px 3px 3px;
   }
}
