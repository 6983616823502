.section-icon {
   display: flex;
   width: 30px;
   height: 30px;
   align-items: center;
   justify-content: center;
   border-radius: 15px;
   color: #fff;
   /*...Font.fontColorWhite;*/
}

.section-icon-purple {
   background-color: #95a3d2;
}

.section-icon-blue {
   background-color: var(--primary);
}

.section-icon-img {
   font-size: 17px;
   color: #fff;
}
