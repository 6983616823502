.loading-container {
   position: absolute;
   width: 100%;
   min-height: 100%;
   align-items: center;
   justify-content: center;
   background: rgb(0, 0, 0, 0.1);
   z-index: 999;
}

.loading-content {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   padding: 60px 30px;
   width: 480px;
   background-color: #fff;
   border-radius: 5px;
   align-self: center;
}

.loading-icon {
   width: 50px;
   height: 30px;
   margin: auto;
}

.loading-icon .ant-spin-dot {
   width: 25px;
   height: 25px;
}

.loading-title {
   margin-top: 15px;
   text-align: center;
   align-self: center;
}

@media (max-width: 1000px) {
   .loading-content {
      width: calc(100vw - 40px);
   }
}
