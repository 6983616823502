@import "../../App.css";

.header .mobile-navigation {
   width: 100%;
   height: 60px;
   background-color: var(--primary);
   display: flex;
   justify-content: center;
   align-items: center;
}

.header .mobile-navigation .navigation {
   padding-left: 5px;
}

.header-user-info {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   padding: 15px 0px 0px 0px;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
}

.header-user-info .section {
   display: flex;
   align-items: center;
   padding: 0px 10px;
}

.header-user-nav {
   flex: 1;
   display: flex;
   justify-content: left;
}

.header-user-section {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-left: 20px;
   border-radius: 16px;
   padding: 1px;
   border: 1px solid var(--border-color);
}

.header-user-info-content {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px 15px 0px 10px;
}

@media (max-width: 1000px) {
   .header-user-info {
      padding: 20px 20px 0px 20px;
   }
   .header-user-section {
      margin-left: 10px;
   }
}

@media (min-width: 1000px) {
   .header-user-info {
      width: var(--main-width);
   }
}
