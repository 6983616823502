.select-provider-container {
   display: relative;
   margin-left: auto !important;
   margin-right: auto !important;
   overflow: auto;
   margin: 20px 0px;
}

.select-provider-change-type {
   margin-top: 10px;
   display: flex;
   justify-content: space-between;
   padding: 10px 0px;
   cursor: pointer;
}

.select-provider-navigate {
   margin: auto;
   font-size: 18px;
   color: #555;
}

.select-provider-page-view {
   align-self: stretch;
   padding: 0px 5px;
   margin-bottom: 10px;
}

.select-provider-whatsapp {
   display: flex;
   justify-content: flex-end;
   padding: 10px 0px 0px 0px;
}

.select-provider-content .page-view {
   padding-top: 0px !important;
}

@media (max-width: 1000px) {
   .select-provider-container {
      padding: 0px 20px;
      min-height: var(--height-mobile);
   }
}

@media (min-width: 1000px) {
   .select-provider-container {
      width: var(--main-width);
      min-height: var(--height);
   }
}
