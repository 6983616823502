.custom-button span {
   display: flex;
}
.custom-button-panel {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   height: 34px;
   width: 34px;
   border-radius: 17px;
   margin: auto;
   cursor: pointer;
}

.custom-button-panel-with-text {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   height: 34px;
   width: max-content;
   border-radius: 17px;
   padding-left: 10px;
   padding-right: 10px;
   cursor: pointer;
}

.custom-button-bg-primary {
   background-color: var(--primary-button);
}

.custom-button-bg-primary:hover {
   background-color: var(--primary-hover-button);
}

.custom-button-bg-primary:active {
   background-color: var(--primary-active-button);
}

.custom-button-bg-secondary {
   background-color: var(--secondary-button);
}

.custom-button-bg-secondary:active,
.custom-button-bg-secondary:hover {
   background-color: var(--secondary-active-button);
}

.custom-button-bg-navigate {
   background-color: var(--navigate-button);
}

.custom-button-bg-navigate:active,
.custom-button-bg-navigate:hover {
   background-color: var(--navigate-active-button);
}

.custom-button-bg-warning {
   background-color: var(--warning-button);
}

.custom-button-bg-warning:active,
.custom-button-bg-warning:hover {
   background-color: var(--warning-active-button);
}

.custom-button-bg-transparent {
   background-color: transparent;
}

.custom-button-bg-navigate {
   background-color: var(--navigate-button);
}

.custom-button-bg-navigate:active,
.custom-button-bg-navigate:hover {
   background-color: var(--navigate-active-button);
}

.custom-button-bg-loading {
   background-color: var(--loading-button);
}

.custom-button-text {
   margin-left: 5px;
}

.custom-button-left-text {
   margin-left: 5px;
   margin-right: 5px;
}

.custom-button-image {
   font-size: 16 px;
   color: #fff;
}
.custom-button-image-dark {
   margin: auto;
   font-size: 16px;
   color: #333;
}
.custom-button-image-navigate {
   margin: auto;
   font-size: 18px;
   color: #fff;
}
.custom-button-image-navigate-dark {
   margin: auto;
   font-size: 18px;
   color: #555;
}
.custom-button-image-delete {
   margin: auto;
   font-size: 18px;
   background-color: #d5392c;
   color: #fff;
}

.big-image {
   font-size: 18px !important;
}
