.transport-type-item {
   padding: 0px 0px 0px 0px;
   overflow: hidden;
   flex-wrap: wrap;
   display: flex;
   align-items: center;
   height: 110px;
   /*border-bottom: 1px solid #eee;*/
}

.transport-type-img {
   background-color: #eee;
   width: 80px;
   height: 80px;
   border-radius: 60px;
}

.transport-type-empty {
   padding: 0px 0px 0px 0px;
   overflow: hidden;
   flex-wrap: wrap;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100px;
   /*border-bottom: 1px solid #eee;*/
}

.transport-type-item-pointer {
   cursor: pointer;
}

.transport-type-item-pointer:hover {
   background-color: var(--super-light-blue);
}

.transport-type-item .image {
   float: left;
   width: 120px;
   margin-left: 20px;
   margin-top: 10px;
   margin-bottom: 10px;
   height: 80px;
   object-fit: cover;
}

.transport-type-item .item-content {
   display: flex;
   flex-direction: row;
   flex: 1;
   /*height: 100px;*/
   margin-left: 15px;
}

.transport-type-item .description {
   color: #555;
   font-size: 15px;
   font-weight: bolder;
   float: left;
   /* width: 205px; */
}

.transport-type-item .more-info {
   font-size: 15px;
   font-weight: 600;
   float: left;
}

.transport-type-item .more-info-section {
   display: flex;
   flex-direction: column;
   margin-right: 15px;
}

.transport-type-item .more-info-title {
   font-size: 13px;
   font-weight: 600;
   float: left;
   color: #888;
}

.transport-type-item .more-info-icon {
   color: #8393ca;
   margin-right: 5px;
   vertical-align: middle;
}

.transport-type-item .more-info-content {
   font-size: 13px;
   font-weight: 600;
   float: left;
   margin-left: 20px;
   color: #555;
}

@media (max-width: 1000px) {
   .transport-type-item {
      width: calc(100vw - 45px) !important;
      height: 85px;
   }

   .transport-type-item .image {
      width: calc(100vw - 320px);
      max-width: 120px;
      margin-left: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      height: calc(100vw - 320px);
      max-height: 120px;
   }

   .new-shipment .transport-type-item .image {
      margin-left: 5px;
   }

   .transport-type-item .item-content {
      margin-left: 10px;
   }

   .transport-type-item .more-info-section {
      margin-right: 5px;
   }
}