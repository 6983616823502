.shipment-view-item {
   padding: 0px 0px 10px 0px;
   margin-bottom: 20px;
   /*...Layout.regularBottomBorder;*/
}

.shipment-view-top {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.shipment-view-shipment-no {
   display: flex;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.shipment-view-content {
   display: flex;
   margin-top: -5px;
   flex-direction: row;
   align-items: center;
}

.shipment-view-pic-image {
   width: 60px;
   height: 60px;
   border-radius: 30px;
   border-color: #ddd;
   border-width: 1px;
   object-fit: cover;
}

.shipment-view-pic-name {
   flex: 1;
   margin-left: 10px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.shipment-view-bottom {
   display: flex;
   padding-left: 15px;
   flex-direction: row;
   align-items: center;
   padding-top: 10px;
}

.shipment-view-origin-destination {
   margin-right: 20px;
}

.shipment-view-title {
   /*...Font.subRegularGreyFont;*/
}

.shipment-view-desc {
   margin-bottom: 5px;
   /*...Font.regularFont;*/
}

.shipment-view-status {
   margin-top: 5px;
   padding: 2px 8px;
   background-color: var(--yellow);
   border-radius: 2px;
}

@media (max-width: 1000px) {
   .shipment-view-item {
      width: calc(100vw - 40px);
   }
}

@media (max-width: 445px) {
   .shipment-view-bottom {
      padding-left: 0px;
   }
   .shipment-view-origin-destination {
      margin-right: 5px;
   }
   .origin-destination-text {
      padding-left: 5px;
   }
}

@media (min-width: 1000px) {
   .shipment-view-item {
      width: 480px;
   }
}
