.browser {
   padding: 20px 0px;
   width: 1000px;
   margin-left: auto;
   margin-right: auto;
}

@media (max-width: 1000px) {
   .browser {
      padding: 20px 20px;
      width: 100vw;
   }
}
