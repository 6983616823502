.search-bar {
   margin-left: 0px;
   width: 335px;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   padding: 12px 0px 0px 30px !important;
}

.search-bar-container {
   width: 100%;
}

.search-bar .ant-input-search {
   width: 305px !important;
   border: 1px solid #2e8db3;
   border-radius: 20px !important;
   background: #fff;
}

.search-bar input {
   font-family: var(--font-family-regular);
   font-weight: 600;
   height: 25px;
   padding: 0px 10px 0px 10px;
   margin: 7px 10px;
   border-width: 0px;
   font-size: 15px;
}

.search-bar button,
.search-bar .ant-input-group-addon {
   border-width: 0px;
   border-radius: 20px !important;
}

.search-bar-category {
   font-size: 14px;
   font-family: var(--font-family-regular);
   color: #fff;
   font-weight: 500;
   background: #0294bb;
   padding: 7px 20px 7px 10px !important;
   border: 0px solid #222;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.search-bar-img {
   object-fit: cover;
   width: 90px;
   height: 70px;
   margin: 0px 20px 0px 0px;
   border: 1px solid #eee;
}

.search-bar-title {
   display: block;
   font-weight: bold;
   font-size: 15px;
   color: #111;
   margin-top: 3px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.search-bar-description {
   display: block;
   font-size: 13px;
   color: #333;
   margin: 4px 0px 0px 0px;
   padding: 4px 0px 0px 0px;
   border-top: 0.5px solid #888;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

/* transport type */

.search-bar-result {
   padding: 0px 0px 0px 0px;
   overflow: hidden;
   flex-wrap: wrap;
   display: flex;
   vertical-align: top;
   height: 100px;
   border-bottom: 1px solid #eee;
   cursor: pointer;
}

.search-bar-result-image {
   float: left;
   width: 120px;
   margin-left: 0px;
   margin-top: 10px;
   margin-bottom: 10px;
   height: 80px;
   object-fit: cover;
}

.search-bar-result-content {
   display: flex;
   flex-direction: row;
   flex: 1;
   height: 100px;
   margin-left: 20px;
}

.search-bar-result-description {
   color: #555;
   font-size: 15px;
   font-weight: bolder;
   float: left;
}

.search-bar-result-more-info {
   font-size: 15px;
   font-weight: 600;
   float: left;
}

.search-bar-result-more-info-section {
   display: flex;
   flex-direction: column;
   margin-right: 15px;
}

.search-bar-result-more-info-title {
   font-size: 13px;
   font-weight: 600;
   float: left;
   color: #888;
}

.search-bar-result-more-info-icon {
   color: #8393ca;
   margin-right: 5px;
   vertical-align: middle;
}

.search-bar-result-more-info-content {
   font-size: 13px;
   font-weight: 600;
   float: left;
   margin-left: 20px;
   color: #555;
}

.search-bar .ant-input,
.search-bar .ant-input-lg,
.search-bar .ant-input-affix-wrapper-focused,
.search-bar .ant-input-affix-wrapper,
.search-bar .ant-input-affix-wrapper:hover {
   border: 0px solid #fff !important;
}

@media (max-width: 1000px) {
   .search-bar-container {
      width: 100vw !important;
   }

   .search-bar {
      width: 100vw !important;
      display: flex;
      background-color: #eee;
      margin: auto !important;
      margin-top: 0px !important;
      padding: 0px 0px !important;
   }

   .search-bar .ant-input-search {
      border: 1px solid var(--primary);
      width: calc(100vw - 20px) !important;
      margin: 10px 10px 10px 10px !important;
      border-radius: 3px !important;
   }
}
