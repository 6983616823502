.input-date {
   margin-top: 20px;
   position: relative;
}

.input-date:hover,
.input-date:focus-within {
   color: #0297be !important;
}

.input-date-label-container {
   position: absolute;
   display: flex;
   justify-content: flex-start;
   width: 100%;
   top: 0;
   padding: 0px 10px;
}

.input-date-label {
   margin-top: -10px;
   background-color: #fff;
   padding: 0px 5px;
}

.input-date-label-loading {
   margin-top: -10px;
}

.input-date-skeleton {
   margin-top: 20px;
   width: 100%;
   padding: 8px 15px 8px 15px;
}
