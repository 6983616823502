.image-upload {
   margin-top: 5px;
   display: flex !important;
   flex-direction: row;
   flex-wrap: wrap;
   align-content: flex-start;
   /* min-height: 112px; */
}

.image-upload-icon {
   font-size: 20px;
}

.image-upload-text {
   font-size: 13px;
}

.image-upload .ant-upload-list {
   display: flex;
}

.image-upload .ant-upload-list-picture-card .ant-upload-list-item {
   padding: 0px !important;
   border: 1px dashed #d9d9d9 !important;
}

.ant-upload-list-item-thumbnail img {
   object-fit: cover !important;
}
