.confirm-container {
   position: absolute;
   min-height: 100%;
   width: 100%;
   align-items: center;
   justify-content: center;
   background: rgb(0, 0, 0, 0.1);
   z-index: 9999;
}

.confirm-content {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   padding: 10px 10px;
   width: 500px;
   background-color: #fff;
   border-radius: 5px;
   align-self: center;
}

.confirm-border {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-width: 0.5px;
   padding-top: 20px;
   padding-bottom: 25px;
   border-radius: 2px;
}

.confirm-title {
   margin-top: 10px;
   font-size: 15px;
}

.confirm-desc {
   margin-top: 5px;
   text-align: center;
   padding: 0px 5px;
   white-space: pre-line;
}

.confirm-button-panel {
   display: flex;
   position: sticky;
   bottom: 0px;
   padding-top: 25px;
   background-color: #fff;
   z-index: 9;
   /*padding-top: 10px;
   display: flex;
   flex-direction: row;
   ...Layout.padding-bottomContent;*/
}

@media (max-width: 1000px) {
   .confirm-content {
      width: calc(100vw - 40px);
   }
}
