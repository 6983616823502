.shipment-detail {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   justify-items: flex-start;
   flex-wrap: wrap;
   margin-left: auto;
   margin-right: auto;
   min-height: calc(100vh - 330px) !important;
   padding-bottom: 20px;
}

.shipment-detail-header {
   width: 100%;
   height: 50px;
   margin: 20px 0px 0px 0px !important;
}

.shipment-detail-container {
   display: flex;
   flex: 1;
   align-items: flex-start;
   justify-content: flex-start;
   flex-wrap: wrap;
   padding-bottom: 20px;
   background-color: #fff;
   width: 100%;
}

.shipment-detail-info {
   display: flex;
   flex-direction: column;
}

.shipment-detail-content {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
}

.shipment-detail-content-admin {
   padding-bottom: 10px;
   margin-bottom: 15px;
}

.shipment-detail-item {
   padding-top: 20px;
   padding-bottom: 10px;
   border-radius: 5px;
   margin-bottom: 0px;
}

.shipment-detail-top {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-self: stretch;
}

.shipment-detail-shipment-no {
   /*...Font.regularFont;*/
   flex-wrap: wrap;
}

.shipment-detail-content {
   display: flex;
   margin-top: -5px;
   flex-direction: row;
   align-items: center;
   background-color: #ffffff;
}

.shipment-detail-pic-image {
   width: 60px;
   height: 60px;
   border-radius: 40px;
   object-fit: cover;
   border: 1px solid var(--border-color);
   /*...Layout.regularBorder;*/
}

.shipment-detail-pic-name {
   flex: 1;
   margin-left: 10px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.shipment-detail-bottom {
   margin-top: 5px;
   align-items: center;
}

.shipment-detail-bottom-content {
   display: flex;
   flex-direction: row;
   margin-top: 10px;
   align-items: flex-start;
}
.shipment-detail-bottom-content-left {
   flex: 1;
}

.shipment-detail-bottom-content-right {
   flex-direction: row;
   display: flex;
   justify-content: space-between;
}

.shipment-detail-bottom-content-right-even {
   flex: 1;
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.shipment-detail-bottom-content-right-sub {
   flex-direction: column;
   display: flex;
   justify-content: flex-start;
}

.shipment-detail-bottom-content-right-center {
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

.shipment-detail-reschedule {
   margin-top: 20px;
   margin-bottom: 10px;
   padding: 10px 10px;
   background-color: var(--highlight-warning);
   border: 1px dashed var(--highlight-warning-border);
}

.shipment-detail-progress {
   padding-top: 10px;
   padding-left: 25px;
   align-items: stretch;
}

.shipment-detail-title {
   /*...Font.subRegularGreyFont;*/
   margin-top: 0px;
}

.shipment-detail-desc {
   padding-right: 7px;
}

.shipment-detail-desc-images {
   display: flex;
   margin-top: 5px;
   flex-direction: row;
   width: calc(100vw - 55px);
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.shipment-detail-desc-image-container {
   padding: 0px;
   border-radius: 4px;
   margin-right: 3px;
   margin-bottom: 3px;
   /*...Layout.regularBorder;*/
}

.shipment-detail-desc-image {
   border-radius: 4px;
   width: calc((100vw - 71px) / 4);
   height: calc((100vw - 71px) / 4);
   object-fit: cover;
}

.shipment-detail-button-panel {
   /*position: absolute;*/
   /*bottom: 0px;*/
   padding: 10px 0px;
   display: flex;
   flex-direction: row;
   /*...Layout.padding-bottomContent;*/
   width: 500px;
   position: sticky;
   bottom: 0px;
   background: #fff;
   min-height: 64px;
}

.shipment-detail-button-divider {
   width: 100%;
   border-top: 0.5px dashed var(--dark-border-color);
   margin-top: 0px;
   padding-bottom: 5px;
}

.shipment-detail-payment-amount {
   width: 500px;
   padding: 3px 0px;
}

.shipment-detail-delivery-progress {
   padding-left: 30px;
   padding-top: 80px;
}

.shipment-detail .route-view {
   margin-top: 10px;
}

.shipment-detail-payment-section {
   margin-bottom: 20px;
}

.shipment-detail-payment-title {
   margin-top: 8px;
   margin-bottom: 6px;
}

.shipment-detail-payment-image {
   padding-top: 10px;
}

.shipment-fee-content {
   padding-top: 5px;
   padding-right: 0px;
}

.shipment-fee-item {
   display: flex;
   padding: 5px 0px 0px 0px;
}

.shipment-fee-item-desc {
   flex: 1;
}

.shipment-fee-item-subtotal {
   display: flex;
}

.shipment-fee-total {
   display: flex;
   padding: 10px 0px 5px 0px;
}

@media (max-width: 1000px) {
   .shipment-detail {
      margin: 10px 0px 0px 0px;
      padding: 0px 0px;
      display: inherit;
   }

   .shipment-detail-header {
      padding: 0px 20px;
      margin: 10px 0px 0px 0px !important;
   }

   .shipment-detail-container {
      height: calc(100vh - 125px);
      overflow: auto;
      padding: 0px 20px;
   }

   .shipment-detail-info {
      width: 100%;
      padding: 0px 0px;
   }

   .shipment-detail-delivery-progress {
      padding-left: 0px;
      padding-top: 10px;
      width: calc(100vw - 40px);
   }

   .shipment-detail-button-panel {
      padding-bottom: 10px;
      background-color: #e5f9ff;
      width: calc(100vw);
      padding: 10px 20px;
   }

   .shipment-detail-payment-amount {
      width: calc(100vw - 40px);
   }
}

@media (min-width: 1000px) {
   .shipment-detail {
      width: 1000px;
   }
   .shipment-detail-container {
      width: 1000px;
   }
   .shipment-detail-info {
      width: 500px;
   }
   .shipment-detail-delivery-progress {
      width: 480px;
   }
}
