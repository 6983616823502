.reschedule {
   width: 100%;
   height: 100%;

   z-index: 99;
   position: absolute;
   left: 0px;
   top: 0px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;

   background: rgb(0, 0, 0, 0.1);
}

.reschedule-container {
   width: var(--popup-width);
   /*height: calc(100vh - 180px);*/

   align-items: stretch;
   justify-content: center;
   border-radius: 3px;
   background-color: #fff;
   margin: auto;
   margin-top: auto;
   margin-bottom: auto;
}

.reschedule-content {
   padding: 20px 10px;
}

.reschedule-calendar {
   display: flex;
   flex-direction: column;
   padding-top: 10px;
}

.reschedule-calendar-panel {
   display: flex;
   flex-direction: column;
}

.reschedule-time-panel {
   padding-top: 10px;
}

.reschedule-time-content {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.reschedule-time-title {
   padding-top: 20px;
}

.reschedule-time {
   margin-top: 5px;
   margin-right: 20px;
   padding: 7px 15px;
}

.reschedule input:hover,
.reschedule input:active {
   border: 0px solid #fff !important;
}

.reschedule-buttons {
   display: flex;
   bottom: 0px;
   padding-top: 25px;
   padding-bottom: 0px;
   background-color: #fff;
   /*padding-top: 10px;
   display: flex;
   flex-direction: row;
   ...Layout.padding-bottomContent;*/
}

@media (max-width: 1000px) {
   .reschedule-container {
      width: calc(100vw - 0px);
   }
}
