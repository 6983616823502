.select-transport-type {
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   min-height: var(--min-height) !important;
}

.select-transport-type-content {
   margin: auto;
   padding: 20px 0px !important;
   overflow: hidden;
}

.select-transort-type-header {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: space-between;
   padding-top: 15px;
   padding-bottom: 15px;
}

.select-transort-type-whatsapp {
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: flex-end;
}

@media (max-width: 1000px) {
   .select-transport-type {
      overflow: auto;
      height: calc(100vh - 80px);
      margin: 10px 0px;
   }

   .select-transport-type-content {
      padding: 20px 20px !important;
   }

   .select-transort-type-header {
      align-items: flex-start;
   }

   .select-transport-type .tkbm {
      margin-top: 5px;
   }
}

@media (min-width: 1000px) {
   .select-transport-type {
      width: var(--main-width) !important;
   }
}
