@import "../../App.css";

.search-info {
   display: flex;
   flex-direction: column;
   padding: 50px 0px 25px 0px;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
}

.search-info-body {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 15px;
   flex-wrap: wrap;
}

.search-info .doodle {
   height: 200px;
   width: 200px;
   object-fit: cover;
}

.search-info-btn-panel {
   margin-left: auto;
   margin-right: auto;
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   width: 330px;
   justify-content: space-around;
}

.search-info-btn {
   display: block;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 10px;
   width: 160px !important;
   font-family: var(--font-family-bold);
   font-size: 14px;
   color: #fff;
   border-radius: 3px !important;
   text-shadow: 0 0 3px #222 !important;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.search-info-icon {
   font-size: 17px;
   position: "relative";
   top: 5px;
   margin-right: 7px;
}

@media (max-width: 1000px) {
   .search-info {
      min-height: var(--min-height-mobile) !important;
      padding: 20px 0px 0px 0px;
      width: 100%;
   }
   .search-info-body {
      padding: 10px 20px 10px 20px;
   }

   .whatsapp {
      width: 150px !important;
   }
}

@media (min-width: 1000px) {
   .search-info {
      height: var(--height) !important;
      min-height: var(--min-height) !important;
      width: var(--main-width);
   }
}
