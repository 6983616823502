.alert-container {
   position: absolute;
   min-height: 100%;
   width: 100%;
   align-items: center;
   justify-content: center;
   background: rgb(0, 0, 0, 0.1);
   z-index: 9999;
}

.alert-content {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   padding: 10px 10px;
   width: 480px;
   background-color: #fff;
   border-radius: 5px;
   align-self: center;
}

.alert-border {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-width: 0.5px;
   padding-top: 20px;
   padding-bottom: 30px;
   padding: 30px 20px 30px 20px;
   border-radius: 2px;
}

.alert-title {
   margin-top: 10px;
   font-size: 15px;
}

.alert-desc {
   margin-top: 10px;
   text-align: center;
}

@media (max-width: 1000px) {
   .alert-content {
      width: calc(100vw - 40px);
   }
}
