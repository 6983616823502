.sub-navigation {
   background-color: var(--header-color);
   width: 100%;
   padding: 5px 5px;
   display: flex;
   align-items: center;
}

@media (max-width: 1000px) {
   .sub-navigation {
      height: 60px;
   }
}
