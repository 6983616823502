.show {
   opacity: 1;
   -webkit-transition: opacity 100ms ease-in;
   -moz-transition: opacity 100ms ease-in;
   -ms-transition: opacity 100ms ease-in;
   -o-transition: opacity 100ms ease-in;
   transition: opacity 100ms ease-in;
}

.hide {
   opacity: 0;
   -webkit-transition: opacity 100ms ease-in;
   -moz-transition: opacity 100ms ease-in;
   -ms-transition: opacity 100ms ease-in;
   -o-transition: opacity 100ms ease-in;
   transition: opacity 100ms ease-in;
}
