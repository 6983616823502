.home-menu {
   margin: 0px 0px;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   position: relative;
   cursor: pointer;
}

.home-menu .image {
   width: calc(100% - 10px);
}

.home-menu .title {
   height: 40px;
   display: flex;
   text-align: center;
   align-items: center;
   padding: 0px 5px 5px 5px;
}

.home-menu .count {
   background-color: var(--purple-regular);
   height: 26px;
   width: 26px;
   border-radius: 13px;
   position: absolute;
   top: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
   right: 0px;
}

.home-menu .count-warning {
   background-color: var(--yellow);
}

.home-menu .count-rating {
   background-color: var(--purple-regular);
}

@media (min-width: 1000px) {
   .home-menu {
      margin: 0px 10px;
   }
}
