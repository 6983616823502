.shipment-steps {
   display: flex;
   background-color: #e5f9ff;
   padding: 0px 20px 0px 5px;
   border-radius: 3px;
   border: 0.5px solid var(--border-color);
}

.shipment-steps-prev {
   margin-top: auto;
   margin-bottom: auto;
   width: 34px;
   height: 34px;
}

.shipment-steps-step {
   margin-top: 7px !important;
   margin-left: 10px !important;
}

.shipment-steps .ant-steps-item-title {
   font-size: 13px;
   font-weight: 600 !important;
   font-family: var(--font-family-regular);
   line-height: 27px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.shipment-steps .ant-steps-item-finish .ant-steps-item-title,
.shipment-steps .ant-steps-item-active .ant-steps-item-title {
   color: var(--font-color-dark) !important;
}

.shipment-steps .ant-steps-item-wait .ant-steps-item-title {
   color: var(--font-color-grey) !important;
}

.shipment-steps .ant-steps-item-description {
   color: var(--font-color-dark) !important;
}

@media (max-width: 1000px) {
   .shipment-steps {
      width: 100%;
      background-color: #e5f9ff;
   }

   .shipment-steps .ant-steps-item-title {
      color: transparent !important;
      width: 0px;
   }

   .shipment-steps .ant-steps-item-finish .ant-steps-item-title,
   .shipment-steps .ant-steps-item-active .ant-steps-item-title {
      color: transparent !important;
   }

   .shipment-steps .ant-steps-item-wait .ant-steps-item-title {
      color: transparent !important;
   }

   .shipment-steps .ant-steps-item-description {
      color: transparent !important;
   }
}

@media (min-width: 1000px) {
   .shipment-steps {
      width: 100%;
   }
}
