.footer {
   width: 100%;
   display: relative;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   padding: 0px 0px 0px 0px;
   border-top: 1px solid var(--border-color);
   font-size: 14px;
   color: #555;
}

.footer-content {
   margin: auto;
   padding: 25px 50px 0px 50px !important;
   margin-bottom: 0px;
   overflow: hidden;
}

.footer-left,
.footer-right {
   float: right;
   overflow: hidden;
}

.footer-left {
   width: 60%;
}

.footer-right {
   width: 40%;
   display: flex;
   flex-wrap: wrap;
   overflow: hidden;
}

.footer img {
   margin: 10px 0px;
   height: 50px;
}

.footer .desc {
   width: 75%;
   margin: 10px 0px;
   padding-bottom: 10px;
   color: #333;
}

.footer .content {
   padding-bottom: 10px;
}

.footer .contact {
   margin: 10px 0px;
}

.footer .contact-icon {
   padding: 0px 10px 5px 0px;
}

.footer .footer-wa {
   cursor: pointer;
}

.footer-items {
   padding: 0px 0px 10px 0px;
   float: left;
   text-align: center;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   overflow: hidden;
}

@media (max-width: 1000px) {
   .footer {
      padding: 20px 20px;
   }

   .footer-content {
      padding: 0px 0px 0px 0px !important;
   }

   .footer-description {
      margin-bottom: 30px;
   }

   .footer-items {
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
   }

   .footer-left {
      display: block;
      width: 100%;
      margin-top: 25px;
      margin-bottom: 10px;
   }

   .footer-right {
      display: block;
      width: 100%;
      margin-top: 10px;
   }
}

@media (min-width: 1000px) {
   .footer-content {
      width: 1100px !important;
   }
}
