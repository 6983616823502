/* 
.fontFamily {
  fontFamily: MuseoSans-Regular,
  fontWeight: 600,
};

.fontFamilyBold {
  fontFamily: Montserrat-Bold,
  fontWeight: 500,
}; 
*/

:root {
   --font-family-regular: "Poppins-Regular";
   --font-family-bold: "Poppins-Bold";
   --font-family-medium: "Gotham-Medium";
   --font-color-darker: #444;
   --font-color-dark: #555;
   --font-color-light-dark: #666;
   --font-color-grey: #777;
   --font-color-white: #fff;
   --font-color-green: #02a29b;
   --font-color-purple: #8393ca;
   --font-color-blue: #3198c1;
   --font-size-super: 17px;
   --font-size-bold: 15px;
   --font-size-big: 14px;
   --font-size-regular: 13px;
   --font-size-sub-regular: 12px;
   --font-size-small: 11px;
   --font-size-tiny: 9px;
}

.caps {
   text-transform: uppercase;
}

.super-bold-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-bold);
   color: var(--font-color-dark);
}

.super-bold-white-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-super);
   color: var(--font-color-white);
}

.super-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-super);
   color: var(--font-color-dark);
}

.bold-bold-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-bold);
   color: var(--font-color-dark) !important;
}

.bold-bold-light-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-bold);
   color: var(--font-color-light-dark) !important;
}

.bold-bold-grey-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-bold);
   color: var(--font-color-grey) !important;
}

.bold-bold-purple-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-bold);
   color: var(--font-color-purple) !important;
}

.bold-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-bold);
   font-weight: 600;
   color: var(--font-color-dark) !important;
}

.bold-white-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-regular);
   color: var(--font-color-white);
}

.big-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-big);
   color: var(--font-color-dark);
}

.big-bold-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-regular);
   color: var(--font-color-light-dark);
}

.big-grey-font {
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: var(--font-size-big);
   color: var(--font-color-grey);
}

.big-white-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-big);
   color: var(--font-color-white);
}

.regular-bold-font {
   font-family: var(--font-family-regular);
   font-weight: 700;
   font-size: var(--font-size-regular);
   color: var(--font-color-dark);
}

.regular-bold-grey-font {
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: var(--font-size-regular);
   color: var(--font-color-grey);
}

.regular-bold-purple-font {
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: var(--font-size-regular);
   color: var(--font-color-purple);
}

.regular-bold-blue-font {
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: var(--font-size-regular);
   color: var(--font-color-blue);
}

.regular-bold-white-font {
   font-family: var(--font-family-bold);
   font-size: var(--font-size-regular);
   color: var(--font-color-white);
}

.regular-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-regular);
   color: var(--font-color-darker);
   font-weight: 500;
}

.regular-grey-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-regular);
   color: var(--font-color-grey);
}

.regular-white-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-regular);
   color: var(--font-color-white);
}

.regular-purple-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-regular);
   color: var(--font-color-purple);
}

.sub-regular-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-sub-regular);
   color: var(--font-color-dark);
}

.sub-regular-bold-font {
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: var(--font-size-sub-regular);
   color: var(--font-color-light-dark);
}

.sub-regular-white-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-sub-regular);
   color: var(--font-color-white);
}

.sub-regular-grey-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-sub-regular);
   color: var(--font-color-grey);
}

.sub-regular-purple-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-sub-regular);
   color: var(--font-color-purple);
}

.small-bold-font {
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: var(--font-size-small);
   color: var(--font-color-dark);
}

.small-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-small);
   color: var(--font-color-dark);
}

.small-grey-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-small);
   color: var(--font-color-green);
}

.tiny-font {
   font-family: var(--font-family-regular);
   font-size: var(--font-size-tiny);
   color: var(--font-color-dark);
}
