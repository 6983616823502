.category-story-item {
   float: left;
   margin: 0px 0px;
   overflow: hidden;
}

.category-story-item .content {
   position: relative;
}

.category-story-item .content img {
   width: 229px;
   height: 229px;
   border-radius: 4px;
}

.category-story-item .content .title {
   display: block;
   color: #111;
   font-family: var(--font-family-regular);
   font-weight: 700;
   font-size: 14px;
   line-height: 20px;
   padding: 20px 0px 10px 0px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.category-story-item .content .length-icon {
   float: left;
   padding: 5px 10px 0px 0px;
   color: #0294bb;
   font-size: 14px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.category-story-item .content .length {
   float: left;
   color: #444;
   font-size: 13px;
   margin-bottom: 40px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

@media (max-width: 1000px) {
   .category-story-item .content img {
      width: 195px;
      height: 195px;
      object-fit: cover;
   }

   .category-story-item .content .title {
      padding: 10px 0px 0px 0px;
   }

   .category-story-item .content .length {
      padding: 0px 0px;
   }
}
