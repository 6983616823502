.square-button-panel {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   padding-left: 10px;
   padding-right: 10px;
   height: 45px;
   border-radius: 3px;
   cursor: pointer;
   flex: 1;
}

.square-button-panel-text-only {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   padding-left: 40px;
   padding-right: 10px;
   height: 45px;
   border-radius: 3px;
   cursor: pointer;
   flex: 1;
}

.square-button-page-panel {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 40px;
   width: 40px;
   border-radius: 3px;
   cursor: pointer;
   margin-right: 5px;
}

/*.square-button-panel:hover,
.square-button-panel-text-only:hover,
.square-button-page-panel:hover {
   border: 1px solid var(--tint-color);
}

.square-button-panel:active,
.square-button-panel-text-only:active,
.square-button-page-panel:active {
   box-shadow: 0 0 0 2px var(--focus-color);
}*/

.square-button-icon {
   margin-right: 5px;
   /*...Font.fontSizeBold;
    ...Font.fontColorWhite;*/
}

.square-button-text {
   /*flex: 1;*/
   margin-right: 5px;
   line-height: 15px;
   /*...Font.boldWhiteFont;*/
}

.square-button-text-only {
   text-align: center;
   flex: 1;
   margin-right: 10px;
   /*...Font.boldWhiteFont;*/
}

.square-button-primary-color {
   background-color: var(--primary);
}

.square-button-regular-color {
   background-color: var(--primary-button);
}

.square-button-regular-color:hover {
   background-color: var(--primary-hover-button);
}

.square-button-regular-color:active {
   background-color: var(--primary-active-button);
}

.square-button-warning-color {
   background-color: var(--warning-button);
}

.square-button-warning-color:hover {
   background-color: var(--warning-hover-button);
}

.square-button-warning-color:active {
   background-color: var(--warning-active-button);
}

.square-button-page-color {
   background-color: #fff;
}
