.input-static {
   position: relative;
   margin-top: 20px;
   cursor: pointer;
   min-height: 42px;
}

.input-static:hover,
.input-static:focus-within {
   color: #0297be !important;
}

.input-static-label-container {
   position: absolute;
   display: flex;
   justify-content: flex-start;
   width: 100%;
   top: 0px;
   padding: 0px 10px;
}

.input-static-label {
   margin-top: -10px;
   background-color: #fff;
   padding: 0px 5px;
}

.input-static-skeleton {
   margin-top: 20px;
   width: 100%;
   padding: 6px 15px 7px 15px;
}

.input-static-content {
   min-height: 40px;
   padding: 10px 15px;
}

.input-static-content:hover {
   border: 1px solid var(--tint-color) !important;
}

.input-static-content:focus-within {
   box-shadow: 0 0 0 2px var(--focus-color) !important;
}
