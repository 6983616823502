.review-home {
  /* display: flex;
  flex-direction: column;
  padding: 5px 0px 25px 0px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; */
  width: 100%;
  display: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  /* height: calc(100vh - 90px) !important; */
}

.review-home-content {
  /* overflow: hidden;
  height: var(--height);
  display: flex;
  flex-direction: column;
  min-height: 310px; */
  margin: 20px 0px !important;
  /*overflow: hidden;
  height: 100%;*/
}
.provider-info-title {
  padding: 10px 10px 5px 10px;
}

@media (max-width: 1000px) {
  .review-home {
     min-height: var(--min-height-mobile) !important;
     padding: 5px 0px 0px 0px;
  }
}

@media (min-width: 1000px) {
  .review-home {
     /*height: var(--height) !important;*/
     min-height: var(--min-height) !important;
     width: var(--main-width);
  }
}