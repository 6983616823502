.booking-category {
   padding: 20px 0px 0px 0px;
   width: var(--main-width) !important;
   margin: auto;
   display: relative;
}

.booking-category-content {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   background-color: #fff !important;
   margin: 10px 0px 0px 0px;
}

.booking-category-title {
   margin-top: 0px;
   font-family: var(--font-family-bold);
   font-size: 20px;
   color: #444;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.booking-category-description {
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: 15px;
   color: #555;
   height: 30px;
   margin-top: 10px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.booking-category-item {
   margin: 0px 1px 30px 0px !important;
   border: 1px solid transparent !important;
   width: 120px !important;
   padding: 0px 0px;
   background-color: #fff;
}

.booking-category-item:hover {
   box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19) !important;
}

.booking-category-item:before,
.booking-category-item:after {
   box-sizing: inherit;
}

.booking-category-item img {
   width: 100px;
   height: 100px;
   margin: auto;
   margin-top: 0px;
}

.booking-category-item .ant-card-body {
   padding: 20px 0px 30px 0px !important;
   margin: 0px 0px 0px 0px !important;
   text-align: center;
}

.booking-category-item .ant-card-meta-title {
   width: 120px;
   position: absolute;
   font-family: var(--font-family-regular);
   font-weight: bold;
   color: #444;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
   word-wrap: break-word; /* Internet Explorer 5.5+ */
   /*word-break: break-all;*/
   white-space: normal;
}

@media (max-width: 1000px) {
   .booking-category {
      padding: 0px 20px 20px 20px;
      width: calc(100vw - 0px) !important;
      overflow: auto;
      height: calc(100vh - 190px);
   }

   .booking-category-content {
      margin: 25px 0px 0px 0px;
      justify-content: flex-start;
   }
   .booking-category-item {
      width: 95px !important;
      padding: 0px 0px;
      background-color: #fff;
      float: left;
      margin: 0px 5px 15px 0px !important;
   }
   .booking-category-item img {
      height: 100px;
      width: 80px;
      margin-top: -45px;
   }

   .booking-category-item .ant-card-body {
      padding: 0px 0px 25px 0px !important;
      text-align: center;
   }

   .booking-category-item .ant-card-meta-title {
      width: 95px;
   }
}

@media (min-width: 1000px) {
   .booking-category {
      width: var(--main-width) !important;
   }
}
