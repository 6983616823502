.trend-analytic-container {
   display: relative;
   margin-left: auto;
   margin-right: auto;
   margin-top: 20px;
   margin-bottom: 0px;
   padding: 20px 0px;
   align-items: center;
   justify-content: center;
}

.trend-analytic-page-view {
   align-self: stretch;
   padding: 0px 5px;
   margin-bottom: 10px;
}

.trend-analytic-content {
   text-align: center;
}

.trend-analytic-content h2 {
   margin: 0px 20px;
}

.recharts-responsive-container {
   margin: 0 auto;
   align-items: center;
   justify-content: center;
}

@media (max-width: 1000px) {
   .trend-analytic-container {
      padding: 20px 0px;
      height: calc(100vh - 140px) !important;
      overflow: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
   }

   .recharts-responsive-container {
      margin: 0px;
      align-items: center;
      justify-content: center;
      width: 100%;
   }

   .trend-analytic-content .title {
      margin-bottom: 0px;
   }
}

@media (min-width: 1000px) {
   .trend-analytic-container {
      width: var(--main-width);
      padding: 0px;
      min-height: 700px !important;
   }
}
