.select-option-container {
   position: absolute;
   min-height: 100%;
   width: 100%;
   align-items: center;
   justify-content: center;
   background: rgb(0, 0, 0, 0.1);
   z-index: 9999;
   top: 0;
   left: 0;
}

.select-option {
   width: var(--popup-width);
   height: calc(100vh - 180px);
   z-index: 99;
   position: absolute;
   display: flex;
   flex-direction: row;
   align-items: center;

   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.select-option-content {
   height: 650px;
   flex: 1;
   align-items: stretch;
   justify-content: center;
   border-radius: 3px;
   background-color: #fff;
   margin: auto;
   margin-top: auto;
   margin-bottom: auto;
}

.select-option-search {
   padding: 10px;
   height: 608px;
   background-color: #fff;
}

.select-option-search-result {
   align-self: stretch;
   padding-bottom: 5px;
   padding-right: 5px;
   max-height: 520px;
   overflow: auto;
}

.select-option input:hover,
.select-option input:active {
   border: 0px solid #fff !important;
}

@media (max-width: 1000px) {
   .select-option {
      width: calc(100vw);
      height: calc(100vh);

      left: 0px;
      top: 0px;
      transform: translate(0%, 0%);
   }

   .select-option-content {
      height: 100vh;
      width: 100vw;
   }

   .select-option-search {
      height: calc(100vh - 60px);
   }

   .select-option-search-result {
      max-height: calc(100vh - 220px);
      padding-right: 0px;
   }
}
