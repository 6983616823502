.delivery-progress {
   margin-top: 20px;
}

.delivery-progress .description {
   display: flex;
   justify-items: center;
}

.delivery-progress .description-title {
   flex: 1;
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

.delivery-progress .description-image {
   display: inline;
}

.delivery-progress .ant-steps-item-title {
   margin-top: -4px;
   font-size: 13px;
   font-weight: 600 !important;
   font-family: var(--font-family-regular);
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.delivery-progress .ant-steps-item-finish .ant-steps-item-title,
.delivery-progress .ant-steps-item-active .ant-steps-item-title {
   color: var(--font-color-dark) !important;
}

.delivery-progress .ant-steps-item-wait .ant-steps-item-title {
   color: var(--font-color-grey) !important;
}

.delivery-progress .ant-steps-item-description {
   color: var(--font-color-dark) !important;
}
