.process-shipment {
   width: 100%;
   height: 100%;

   z-index: 99;
   position: absolute;
   left: 0px;
   top: 0px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;

   background: rgb(0, 0, 0, 0.1);
}

.process-shipment-container {
   width: var(--popup-width);
   /*height: calc(100vh - 180px);*/

   align-items: stretch;
   justify-content: center;
   border-radius: 3px;
   background-color: #fff;
   margin: auto;
   margin-top: auto;
   margin-bottom: auto;
}

.process-shipment .remarks {
   height: 82px !important;
}

.process-shipment .remarks-title {
   margin-top: 10px;
   margin-bottom: 5px;
}

.process-shipment-search {
   padding: 10px;
   height: 608px;
   background-color: #fff;
}

.process-shipment-search-result {
   align-self: stretch;
   padding-bottom: 5px;
   padding-right: 5px;
   max-height: 520px;
   overflow: auto;
}

.process-shipment input:hover,
.process-shipment input:active {
   border: 0px solid #fff !important;
}

.process-shipment-buttons {
   display: flex;
   background-color: #fff;
}

@media (max-width: 1000px) {
   .process-shipment-container {
      width: calc(100vw - 20px);
   }

   .process-shipment-content {
      padding: 0px 15px;
      height: calc(100vh - 145px);
      overflow: auto;
   }

   .process-shipment-bottom {
      width: 100%;
      padding: 0px 15px;
   }

   .process-shipment-buttons {
      padding-top: 10px;
      padding-bottom: 0px;
   }
}

@media (min-width: 1000px) {
   .process-shipment-content {
      padding: 0px 15px;
      overflow: auto;
   }

   .process-shipment-bottom {
      width: 100%;
      padding: 0px 15px;
   }

   .process-shipment-buttons {
      padding-top: 10px;
      padding-bottom: 10px;
   }

   .process-shipment-buttons {
      padding-top: 10px;
      padding-bottom: 10px;
   }
}

@media (min-width: 1000px) and (max-height: 900px) {
   .process-shipment-content {
      height: calc(100vh - 145px);
   }
}
