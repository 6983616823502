.forward {
   display: flex;
   margin-left: auto;
   margin-right: auto;
   flex-wrap: wrap;
   overflow: hidden;
   min-height: var(--min-height) !important;
   justify-content: center;
   align-items: center;
}

@media (max-width: 1000px) {
   .forward {
      flex-direction: column;
      min-height: calc(100vh - 80px) !important;
      justify-content: flex-start;
   }
}
@media (min-width: 1000px) {
   .forward {
      width: 725px;
   }
}
