.register-transporter {
   height: calc(100vh - 310px);
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px 20px;
}

.register-transporter .info {
   text-align: center;
   padding-bottom: 20px;
}

@media (max-width: 1000px) {
   .register-transporter {
      height: calc(100vh - 120px);
   }
}
