.float {
   position: fixed;
   width: 60px;
   height: 60px;
   bottom: 40px;
   right: 40px;
   background-color: #00b91a;
   color: #fff;
   border-radius: 50px;
   text-align: center;
   font-size: 36px;
   box-shadow: 2px 2px 3px #999;
   z-index: 2;
}

.cursor-pointer {
   cursor: pointer;
}

.my-float {
   margin-top: 13px;
}

.home-booking {
   display: none;
}

@media (max-width: 1000px) {
   .home {
      height: calc(100vh - 120px);
   }

   .home-content {
      height: calc(100vh - 120px);
      margin: 0px 0px;
      overflow: auto;
   }

   .home-booking {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 2px;
      left: calc(50vw - 34px);
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
      background-color: white;
      padding: 0px 5px 2px 5px;
   }

   .home-booking-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      color: var(--primary-button);
   }

   .home-booking-btn .home-booking-icon {
      font-size: 28px;
      color: var(--primary-button);
      margin: 0px 0px 5px 0px;
   }

   .float {
      top: 380px;
      right: 20px;
   }
}
