.showcase-item-panel {
   visibility: visible;
   overflow: hidden !important;
   width: 100%;
   height: 500px;
   max-height: none;
   left: 0px;
   position: relative;
   display: block;
   text-align: center;
}

.showcase-item-img {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   width: 100%;
   height: 100%;
   opacity: 1;
   visibility: inherit;
   z-index: 20;
   text-align: center;
}

@media (max-width: 1000px) {
   .showcase-item-panel {
      width: 100vw !important;
      height: 250px;
   }
   .showcase-item-btn {
      font-size: 25px;
      bottom: 75px;
   }
}

@media (min-width: 600px) and (max-width: 1100px) {
   .showcase-item-panel {
      height: 450px;
   }
   .showcase-item-btn {
      font-size: 25px;
      bottom: 75px;
   }
}

@media (min-width: 1000px) {
   .showcase-item-img {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
   }
}
