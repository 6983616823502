.input-sign {
   position: relative;
   display: flex;
   margin-top: 20px;
   min-height: 42px !important;
}

.input-sign:hover,
.input-sign:focus-within {
   color: #0297be !important;
}

.input-sign-label-container {
   position: absolute;
   display: flex;
   justify-content: flex-start;
   width: 100%;
   z-index: 9;
   padding: 0px 10px;
}

.input-sign-label {
   margin-top: -10px;
   background-color: #fff;
   padding: 0px 5px;
}

.input-sign-skeleton {
   margin-top: 20px;
   width: 100%;
   padding: 8px 15px 8px 15px;
}

.input-sign-pad {
   flex: 1;
}

.input-sign-button-container {
   position: absolute;
   display: flex;
   justify-content: flex-end;
   z-index: 1;
   bottom: 0px;
   right: 0px;
   padding: 5px 5px;
}
