/*.why-us-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}*/

.why-us-item {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   border: 1px solid #eee;
   border-radius: 4px;
   overflow: hidden;
   margin-bottom: 25px;
   background-color: #fff;
}

.why-us-item-left {
   padding: 40px 30px;
}

.why-us-item-right {
   width: 320px;
   padding: 30px 0px;
}

.why-us-item-icon {
   font-size: 60px;
}

.why-us-item .color-0 {
   color: #ffb80f;
}

.why-us-item .color-1 {
   color: #0294bb;
}

.why-us-item .color-2 {
   color: #59be5c;
}

.why-us-item .color-3 {
   color: #8054c6;
}

.why-us-item-title {
   text-align: left;
   font-family: var(--font-family-bold);
   font-size: 16px;
   color: #444;
   padding: 0px 0px;
}

.why-us-item-info {
   text-align: left;
   font-size: 14px;
   font-weight: 600;
   color: #555;
   padding: 0px 0px;
   margin-right: 15px;
}

@media (max-width: 1000px) {
   .why-us-item {
      margin-top: 20px;
      margin-bottom: 15px;
   }

   .why-us-item-left {
      padding: 30px 20px;
   }
   .why-us-item-right {
      width: 300px;
      padding: 20px 10px 20px 0px;
   }

   .why-us-item-icon {
      font-size: 40px;
   }
}
