.change-category {
   width: 100%;
   padding: 10px 10px !important;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   height: var(--height) !important;
   min-height: var(--min-height) !important;
}

.change-category-content {
   margin: auto;
   padding: 20px 0px 0px 0px !important;
   margin-bottom: 20px;
   overflow: hidden;
}

@media (max-width: 1000px) {
   .change-category {
      /*min-height: var(--min-height-mobile) !important;*/
      height: calc(100vh - 120px) !important;
   }

   .change-category-content {
      padding: 20px 0px 0px 0px !important;
   }

   .change-category-content-steps {
      padding: 0px 20px 20px 20px;
   }
}

@media (min-width: 1000px) {
   .change-category {
      width: var(--main-width) !important;
   }
}
