.bottom-navigation-container {
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   width: 100%;
   background-color: var(--regular-background);
   position: absolute;
   bottom: 0px;
}

.bottom-navigation {
   width: 100%;
   position: sticky;
   bottom: 0px;
}

.bottom-navigation .icon {
   font-size: 24px;
   color: var(--menu-bottom-regular);
}

.bottom-navigation .icon-active {
   font-size: 24px;
   color: var(--menu-bottom-regular-active);
}

.bottom-navigation .menus {
   display: flex;
   justify-content: space-around;
   align-items: center;
   height: 60px;
   font-size: 24px;
}

.bottom-navigation .menu-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 75px;
}

.bottom-navigation .menu {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 36px;
   width: 36px;
}

.bottom-navigation .menu-active {
   background-color: var(--light-blue);
   border-radius: 18px;
}

.bottom-navigation .sub-menus {
   display: flex;
   flex-direction: column;
}

.bottom-navigation .sub-menu-item-container {
   height: 60px;
   background-color: #f7f7f7;
   position: relative;
}

.bottom-navigation .sub-menu-item {
   position: absolute;
   padding: 18px 20px;
   -webkit-animation: animateShow 300ms normal ease-out;
   animation: animateShow 300ms normal ease-out;
   overflow: hidden;
}

.bottom-navigation .sub-menu-item-hide {
   position: absolute;
   padding: 10px 20px;
   -webkit-animation: animateHide 300ms normal ease-out;
   animation: animateHide 300ms normal ease-out;
   overflow: hidden;
   opacity: 0;
}

.bottom-navigation .sub-menus-hide {
   display: flex;
   justify-content: center;
   height: 24px;
}

.bottom-navigation .sub-menus-hide-btn {
   padding: 0px 20px;
}

.bottom-navigation .sub-menus-hide-icon {
   font-size: 24px;
   color: #555;
}

/*@-webkit-keyframes floatBubble {
   0% {
      height: 0px;
   }
   100% {
      height: 200px;
   }
}*/
@keyframes animateShow {
   0% {
      top: 20px;
      opacity: 0;
   }
   100% {
      top: 0px;
      opacity: 1;
   }
}

@keyframes animateHide {
   0% {
      top: 0px;
      opacity: 1;
   }
   100% {
      top: 20px;
      opacity: 0;
   }
}
