.customer-view-item {
   width: 480px;
   padding: 0px 0px 10px 0px;
   margin-bottom: 15px;
   /*...Layout.regularBottomBorder;*/
}

.customer-view-content {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.customer-view-pic-image {
   width: 70px;
   height: 70px;
   border-radius: 35px;
   border: 1px solid #eee;
   object-fit: cover;
   background-color: #eee;
}

.customer-view-pic {
   flex: 1;
   margin-left: 10px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.customer-view-detail {
   display: flex;
   flex-direction: row;
   padding-top: 0px;
}

.customer-view-provider {
   padding: 3px 8px;
   background-color: var(--purple-regular);
   border-radius: 2px;
   margin-right: 5px;
}

.customer-view-status {
   padding: 2px 8px;
   background-color: var(--notif-yellow);
   border-radius: 2px;
}

.customer-view-item .P {
   background-color: var(--notif-red);
}

@media (max-width: 1000px) {
   .customer-view-item {
      width: calc(100vw - 20px);
   }
}

@media (min-width: 1000px) {
}
