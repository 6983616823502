.search-input {
   display: flex;
   padding: 0px 4px 0px 15px;
   border-radius: 17px;
}

.search-input:hover,
.search-input:focus-within {
   color: #0297be !important;
}

.search-input .ant-input,
.search-input .ant-input:focus {
   flex: 1;
   padding: 5px 0px;
   /* border-radius: 16px; */
   border: 1px solid #fff !important;
}

.search-input:hover {
   border: 1px solid var(--tint-color);
}

.search-input:focus-within {
   box-shadow: 0 0 0 2px var(--focus-color);
}

@media (max-width: 1000px) {
   .search-input {
      flex: 1;
   }
}

@media (min-width: 1000px) {
   .search-input {
      width: 250px;
   }
}
