.category-useful-info {
   padding: 30px 0px;
}

.category-useful-info-header {
   font-size: 15px;
   font-weight: 600;
   color: #333;
   padding: 0px 0px;
}

.category-useful-info-desc {
   font-size: 13px;
   padding: 0px 0px 20px 0px;
}

.category-useful-info .ant-collapse-header {
   font-size: 14px;
   font-weight: 600;
}

.category-useful-info-item-desc {
   font-size: 13px;
}
