.otp {
   display: flex;
   margin-left: auto;
   margin-right: auto;
   flex-wrap: wrap;
   overflow: hidden;
   height: var(--height) !important;
   min-height: var(--min-height) !important;
   justify-content: center;
   align-items: center;
}

.otp .ant-input {
   font-size: 15px;
   border: 1px solid #eee !important;
}

.otp-left {
   display: flex;
   width: 300px;
   padding: 0px 0px;
   overflow: hidden;
   margin: auto;
   justify-content: center;
}

.otp-right {
   width: 400px;
   overflow: hidden;
   margin: auto;
}

.otp .doodle {
   height: 300px;
   width: 300px;
   object-fit: cover;
}

.otp .title {
   font-family: var(--font-family-bold);
   font-size: 20px;
}

.otp .desc {
   padding: 10px 0px;
   font-weight: 600;
   font-size: 15px;
   display: flex;
}

.otp .error {
   padding: 10px 0px;
   font-size: 15px;
   font-weight: 600;
   color: #f57;
}

.otp-form {
   margin: auto;
   overflow: hidden;
}

.otp-form input {
   display: block;
   margin-top: 15px;
}

.otp-form button {
   margin-top: 10px !important;
   font-size: 14px;
   line-height: 14px;
   font-family: var(--font-family-bold);
   padding: 14px 24px;
   height: 42px;
   display: block;
}

.otp .error {
   padding: 10px 0px;
   font-size: 15px;
   font-weight: 600;
   color: #f57;
}

.otp .submit {
   width: 135px;
}

@media (max-width: 1000px) {
   .otp-container {
      height: calc(100vh - 120px) !important;
      overflow: auto !important;
   }
   .otp-container-no-nav {
      height: calc(100vh - 60px) !important;
   }
   .otp {
      flex-direction: column;
      justify-content: flex-start;
   }

   .otp-left {
      padding-top: 20px;
   }

   .otp-right {
      flex: 1;
      max-width: calc(100vw - 40px);
   }

   .otp .doodle {
      height: 220px;
      width: 220px;
      object-fit: cover;
   }
}
@media (min-width: 1000px) {
   .otp {
      width: 725px;
   }
}
