.process-complaint {
   width: 100%;
   height: 100%;

   z-index: 99;
   position: absolute;
   left: 0px;
   top: 0px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;

   background: rgb(0, 0, 0, 0.1);
}

.process-complaint-container {
   width: var(--popup-width);

   align-items: stretch;
   justify-content: center;
   border-radius: 3px;
   background-color: #fff;
   margin: auto;
   margin-top: auto;
   margin-bottom: auto;
}

.process-complaint .response,
.process-complaint .resolution {
   margin-top: 15px;
}

.process-complaint .response-input,
.process-complaint .resolution-input {
   margin-top: 20px;
}

.process-complaint .user {
   display: flex;
   justify-content: space-between;
}

.process-complaint .remarks {
   height: 82px !important;
}

.process-complaint .remarks-title {
   margin-top: 10px;
   margin-bottom: 5px;
}

.process-complaint input:hover,
.process-complaint input:active {
   border: 0px solid #fff !important;
}

.process-complaint-buttons {
   display: flex;
   background-color: #fff;
}

.process-complaint .input-select-select .ant-select-selector {
   width: 320px !important;
}

@media (max-width: 1000px) {
   .process-complaint-container {
      width: calc(100vw - 20px);
   }

   .process-complaint-content {
      padding: 0px 15px;
      height: calc(100vh - 145px);
      overflow: auto;
   }

   .process-complaint-bottom {
      width: 100%;
      padding: 0px 15px;
   }

   .process-complaint-buttons {
      padding-top: 10px;
      padding-bottom: 0px;
   }

   .process-complaint .input-select-select .ant-select-selector {
      width: calc(100vw - 32px) !important;
   }
}

@media (min-width: 1000px) {
   .process-complaint-content {
      padding: 0px 15px;
      overflow: auto;
   }

   .process-complaint-bottom {
      width: 100%;
      padding: 0px 15px;
   }

   .process-complaint-buttons {
      padding-top: 10px;
      padding-bottom: 10px;
   }

   .process-complaint-buttons {
      padding-top: 10px;
      padding-bottom: 10px;
   }
}

@media (min-width: 1000px) and (max-height: 900px) {
   .process-complaint-content {
      height: calc(100vh - 145px);
   }
}
