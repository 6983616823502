.cmn-places {
   /*flex: 1;*/
   overflow: auto;
   /*height: 600px !important;
   max-height: 600px !important;*/
   background-color: #fff;
   padding: 0px 15px;
}

.cmn-places .place-container {
   align-self: stretch;
   padding-bottom: 5px;
   background-color: #fff;
   height: 450px;
}

.cmn-places .place-item {
   justify-content: flex-start;
   align-items: flex-start;
   align-self: stretch;
   flex-wrap: wrap;
   padding: 10px 0px;
   background-color: #fff;
   /*...Layout.regularBottomBorder;*/
}

.cmn-places .place-name {
   align-items: stretch;
   flex-wrap: wrap;
   padding-bottom: 2px;
   /*...Font.regularBoldPurpleFont;*/
}

.cmn-places .place-address {
   align-items: stretch;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

@media (max-width: 1000px) {
   .cmn-places {
      height: calc(100vh - 200px);
   }
}

@media (min-width: 1000px) {
   .cmn-places {
      flex: 1;
   }
}
