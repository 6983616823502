.transportType {
   padding: 0px 0px;
   margin-top: 10px;
   width: 100%;
}

@media (max-width: 1000px) {
   .transportTypes {
      padding: 0px 0px !important;
   }
}
