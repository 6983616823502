.service-view-item {
   width: 480px;
   padding: 0px 0px 10px 0px;
   margin-bottom: 15px;
   /*...Layout.regularBottomBorder;*/
}

.service-view-content {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.service-view-transport-type-image {
   width: 75px;
   height: 75px;
   border-radius: 45px;
   border: 1px solid #fff;
   object-fit: cover;
   background-color: #fff;
}

.service-view-pic {
   flex: 1;
   margin-left: 10px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.service-view-detail {
   display: flex;
   flex-direction: row;
   padding-top: 0px;
}

.service-view-pvd {
   padding: 3px 8px;
   background-color: var(--purple-regular);
   border-radius: 2px;
   margin-right: 5px;
   height: 25px;
   overflow: hidden;
}

.service-view-status {
   padding: 2px 8px;
   background-color: var(--blue-dark);
   border-radius: 2px;
}

.service-view-item .P {
   background-color: var(--notif-red);
}

.service-view-item .D {
   background-color: var(--yellow);
}

@media (max-width: 1000px) {
   .service-view-item {
      width: calc(100vw - 40px);
   }
}

@media (min-width: 1000px) {
}
