.why-us {
   width: 100%;
   background-image: linear-gradient(to bottom, rgb(218, 239, 248, 0), rgb(218, 239, 248, 0.75));
   display: relative;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
}

.why-us-content {
   margin: auto;
   padding: 0px 50px 0px 50px !important;
   margin-bottom: 20px;
   overflow: hidden;
}

.why-us-title {
   font-family: var(--font-family-bold);
   font-size: 20px;
   color: #444;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.why-us-description {
   font-weight: 600;
   font-size: 15px;
   color: #555;
   height: 30px;
   margin-bottom: 10px !important;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.why-us-items {
   padding: 20px 0px 10px 0px;
   float: left;
   text-align: center;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   overflow: hidden;
}

@media (max-width: 1000px) {
   .why-us {
      padding: 0px 20px;
   }

   .why-us-content {
      padding: 0px 0px !important;
   }
   .why-us-description {
      margin-bottom: 30px;
   }
   .why-us-items {
      margin-bottom: 10px !important;
   }
}

@media (min-width: 1000px) {
   .why-us-content {
      width: 1100px !important;
   }
}
