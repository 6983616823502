@import '../../../App.css';

.providers {
   display: flex;
   flex-direction: column;
   padding: 5px 0px 25px 0px;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
}

.providers-header {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 15px;
   flex-wrap: wrap;
}

.providers-header-search {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   flex: 1;
}

.providers-header-button {
   display: flex;
   justify-content: flex-end;
}

.providers-header-search-section {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   padding: 5px 10px 5px 0px;
}

.providers-header-search-icon {
   margin-right: 5px;
}

.providers-header-search-input {
   display: flex;
   align-items: center;
}

.providers-header-search-select {
   flex: 1;
}

@media (max-width: 1000px) {
   .providers {
      min-height: var(--min-height-mobile) !important;
      padding: 10px 0px 0px 0px;
   }
   .providers-header {
      padding: 0px 20px 10px 20px;
   }
   .providers-header-search {
      min-width: calc(100vw - 40px);
   }
   .providers-header-search-section {
      padding: 5px 0px;
   }
   .providers-header-search-input {
      flex: 1;
   }
   .providers-header-button {
      padding-top: 5px;
      flex: 1;
   }
   .providers .search-result {
      margin-top: 10px;
      height: calc(100vh - 245px) !important;
      padding: 0px 20px;
      overflow: auto;
   }
   .providers .search-result-no-nav {
      margin-top: 10px;
      height: calc(100vh - 185px) !important;
      padding: 0px 20px;
      overflow: auto;
   }
}

@media (max-width: 450px) {
   .providers-header-search-section {
      width: 100%;
   }
}

@media (min-width: 1000px) {
   .providers {
      height: var(--height) !important;
      min-height: var(--min-height) !important;
      width: var(--main-width);
   }
   .providers-header-search-select {
      width: 210px !important;
   }
}
