.provider-info {
   width: 100%;
   display: relative;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   /* height: calc(100vh - 90px) !important; */
   min-height: 850px;
}

.provider-info-content {
   margin: 20px 0px !important;
   /*overflow: hidden;
   height: 100%;*/
}

.provider-info-map {
   display: flex;
   flex-direction: column;
   margin-top: 10px;
   width: val(--main-width);
   height: calc(100vh - 530px) !important;
   min-height: 500px;
}

.provider-info .provider-info-calendar-panel {
   flex: 1;
   display: flex;
   flex-direction: column;
}

.provider-info-calendar-title {
   margin-top: 10px;
   margin-right: 20px;
   text-align: right;
}

.provider-info .provider-info-calendar {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   margin-top: 5px;
   margin-right: 20px;
}

.provider-info .bottom-selection {
   display: flex;
   padding: 15px;
   background-color: #fff;
}
.provider-info .origin-text {
   flex: 1;
}
.provider-info .distance-text {
   margin-left: 5px;
   /*...Font.fontFamilyBold;
  ...Font.fontSizeBold;
  ...Font.fontColorPurple;*/
}
.provider-info .destination-text {
   flex: 1;
   /*...Font.bigFont;*/
}
.provider-info .duration-text {
   margin-left: 5px;
   /*...Font.fontFamilyBold;
  ...Font.fontSizeBold;
  ...Font.fontColorPurple;*/
}

/*******************************/

.provider-info .content {
   padding: 5px 10px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: row;
   background-color: #ffffff;
   /*...Layout.regularBorder;*/
}

.provider-info .section {
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

.provider-info .section-info {
   display: flex;
   flex-direction: column;
   padding: 10px 0px 10px 15px;
}

.provider-info .section-info-rating {
   display: flex;
   flex-direction: row;
   padding-top: 5px;
}

.provider-info .provider-info-desc {
   padding: 0px 10px;
}

.provider-info .provider-info-images {
   padding: 0px 10px 20px 10px;
}

.provider-info .provider-info-category {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding: 0px 5px;
}

.provider-info .provider-info-category-item {
   padding: 5px 5px;
}

.provider-info .provider-info-category-img {
   width: 100px;
   height: 100px;
   padding: 5px 5px;
   object-fit: contain;
}

.provider-info .provider-info-category-label {
   width: 100%;
   text-align: center;
}

.provider-info .provider-info-title {
   padding: 10px 10px 5px 10px;
}

.provider-info .provider-info-review {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding-bottom: 20px;
}

@media (max-width: 1000px) {
   .provider-info {
      height: calc(100vh - 80px) !important;
      min-height: calc(100vh - 80px) !important;
      overflow: auto;
      margin: 10px 0px;
   }

   .provider-info-content {
      padding: 0px 15px !important;
      margin: 0px 0px !important;
   }

   .provider-info .provider-info-review-item {
      display: flex;
      flex-direction: row;
      width: calc(100%);
   }

   .provider-info .provider-info-category-img {
      width: calc((100vw - 60px) / 4);
      height: calc((100vw - 60px) / 4);
      padding: 0px 0px;
   }
}

@media (max-width: 445px) {
   .provider-info .section-info-rating {
      flex-direction: column;
   }
}

@media (min-width: 1000px) {
   .provider-info {
      width: 1000px !important;
   }
}
