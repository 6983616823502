.input-number {
   margin-top: 20px;
   position: relative;
}

.input-number:hover,
.input-number:focus-within {
   color: #0297be !important;
}

.input-number-label-container {
   position: absolute;
   display: flex;
   justify-content: flex-start;
   width: 100%;
   padding: 0px 10px;
   top: 0px;
}

.input-number-label {
   margin-top: -10px;
   background-color: #fff;
   padding: 0px 5px;
}

.input-number-skeleton {
   margin-top: 20px;
   width: 100%;
   padding: 6px 15px 7px 15px;
}
