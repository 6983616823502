.popup-layout {
   background-color: #fff;
}

.popup-layout .ant-input-focused {
   color: #888 !important;
}

.popup-layout .ant-input {
   font-size: 14px !important;
   border: 1px solid #d9d9d9 !important;
   padding: 8px 15px;
   height: 42px !important;
}

.popup-layout .ant-input:hover {
   border: 1px solid var(--tint-color) !important;
}

.popup-layout .ant-input:focus-within {
   box-shadow: 0 0 0 2px var(--focus-color) !important;
}

.popup-layout .ant-input-number {
   width: 395px !important;
   height: 40px !important;
}

.popup-layout .ant-input-number input {
   margin-top: 5px !important;
   font-size: 14px !important;
   font-weight: 600 !important;
}

.popup-layout .input-select-label-container {
   width: 100%;
}

.popup-layout .input-select-select .ant-select-selector {
   width: 395px !important;
}

.popup-layout .title {
   font-family: var(--font-family-bold);
   font-size: 24px;
}

.popup-layout .desc {
   padding-bottom: 15px;
   font-weight: 600;
   font-size: 15px;
}

.popup-layout-form {
   margin: auto;
}

.popup-layout-form input {
   display: block;
   margin-left: auto !important;
   margin-right: auto !important;
   margin-top: 15px;
   overflow: hidden;
}

.popup-layout-form-buttons {
   font-size: 14px;
   line-height: 14px;
   font-family: var(--font-family-bold);
   padding: 14px 24px;
   height: 42px;
   display: block;
}

.popup-layout-agree-tnc {
   padding-top: 10px;
   padding-bottom: 10px;
   font-size: 14px !important;
   font-weight: 600;
}

.popup-layout .error {
   padding: 10px 0px 0px 0px;
   font-size: 14px;
   font-weight: 600;
   color: #f57;
}

.popup-layout .submit {
   width: 135px;
}

.popup-layout .action-button {
   margin-top: 20px !important;
   display: flex;
}

.popup-layout .action-button button {
   font-size: 14px;
   line-height: 14px;
   font-family: var(--font-family-bold);
   padding: 13px 24px;
   height: 42px;
   width: 100px;
   margin-right: 10px;
}

@media (max-width: 1000px) {
   .popup-layout {
      flex-direction: column;
      width: calc(100vw - 40px) !important;
      /*min-height: calc(100vh - 80px) !important;*/
   }

   .popup-layout-full {
      width: calc(100vw) !important;
      height: calc(100vh) !important;
   }

   .popup-layout-form-full {
      padding: 15px 0px 0px 0px;
      height: calc(100vh - 44px) !important;
   }

   .popup-layout-left {
      padding-top: 0px;
      height: 250px !important;
   }

   .popup-layout-right {
      flex: 1;
   }

   .popup-layout .doodle {
      height: 250px;
      width: 250px;
      object-fit: cover;
   }
}
@media (min-width: 1000px) {
   .popup-layout {
      /*height: var(--height) !important;*/
      /*min-height: var(--min-height) !important;*/
      width: 500px;
   }

   .popup-layout-form {
      padding: 15px 0px 0px 0px;
   }
}
