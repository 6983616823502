.booking-regular {
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   height: var(--height) !important;
   min-height: var(--min-height) !important;
}

.booking-regular-content {
   margin: auto;
   padding: 20px 0px 0px 0px !important;
   margin-bottom: 20px;
   overflow: hidden;
}

.booking-regular-category {
   height: calc(var(--height) - 65px);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.booking-regular-category-description {
   text-align: center;
}

.booking-regular-whatsapp {
   height: 75px;
}

@media (max-width: 1000px) {
   .booking-regular {
      /*min-height: var(--min-height-mobile) !important;*/
      height: calc(100vh - 120px) !important;
   }

   .booking-regular-content {
      padding: 20px 0px 0px 0px !important;
   }

   .booking-regular-category {
      height: calc(100vh - 185px);
   }
}

@media (min-width: 1000px) {
   .booking-regular {
      width: var(--main-width) !important;
   }
}
