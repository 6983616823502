.about-us {
   min-height: calc(100vh - 305px);
   width: 1000px;
   margin-left: auto;
   margin-right: auto;
   padding: 30px 0px 0px 0px;
}

.about-us .title {
   padding: 0px 0px 10px 0px;
   text-align: center;
}

.about-us .content {
   text-align: center;
   padding: 0px 0px 20px 0px;
}

.about-us .content-left {
   text-align: left;
   padding: 0px 0px 20px 0px;
}

.about-us .content-left ol {
   padding: 10px 0px 0px 10px;
   margin: 0px 0px;
}

@media (max-width: 1000px) {
   .about-us {
      height: calc(100vh - 140px);
      width: 100%;
      padding: 20px 20px;
      overflow: auto;
      margin: 10px 0px;
   }

   .about-us-content {
      min-height: calc(100vh - 190px);
      margin: 0px 0px;
      overflow: auto;
   }
}
