/*.it-work-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}*/

.it-work-item {
   display: block;
   border-radius: 4px;
   overflow: hidden;
   margin-bottom: 25px;
   width: 230px;
   padding: 10px 0px;
   margin: 0px 5px 0px 5px;
}

.it-work-item-top {
   padding: 0px 20px;
   margin: auto;
   overflow: hidden;
   background-color: #fff;
   width: 100px;
}

.it-work-item-round {
   width: 60px;
   height: 60px;
   font-family: var(--font-family-bold);
   font-size: 20px;
   text-align: center;
   line-height: 60px;
   border-radius: 30px;
   background: #fff;
   color: #555;
   display: block;
   border: 1px solid #bde3f3;
   overflow: hidden;
}

.it-work-item-middle {
   margin: auto;
   text-align: center;
   display: block;
   font-family: var(--font-family-bold);
   font-size: 16px;
   padding: 10px 0px;
   color: #555;
}

.it-work-item-bottom {
   margin: auto;
   text-align: center;
   display: block;
   font-size: 14px;
   font-weight: 600;
   color: #666;
}

@media (max-width: 1000px) {
   .it-work-item {
      width: 195px;
   }
}
