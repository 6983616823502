@import "../../App.css";

.shipment {
   display: flex;
   flex-direction: column;
   padding: 5px 0px 25px 0px;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
}

.shipment-header {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px 0px 10px 0px;
}

.shipment-header-search {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   flex: 1;
   width: 100%;
}

.shipment-header-search-section {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   padding: 5px 10px 5px 0px;
}

.shipment-header-search-icon {
   margin-right: 5px;
}

.shipment-title {
   display: flex;
   padding-left: 10px;
   align-items: center;
   flex: 1;
}

@media (max-width: 1000px) {
   .shipment {
      min-height: var(--min-height-mobile) !important;
      padding: 5px 0px 0px 0px;
   }
   .shipment-header {
      width: calc(100vw - 40px);
      margin: 0px 20px;
      flex-direction: column;
   }
   .shipment-header-search-section {
      padding: 5px 0px;
      flex: 1;
   }
   .shipment-header-search-input {
      flex: 1;
   }
   .shipment-header-search-result {
      height: calc(100vh - 240px);
      margin: 10px 0px;
      padding: 0px 20px 0px 20px;
      overflow: auto;
   }
   .shipment-header-search-result-with-actions {
      height: calc(100vh - 240px);
   }
   .shipment-header-search-result-with-actions-no-nav {
      height: calc(100vh - 185px);
   }
   .shipment-header-search-result-no-actions {
      height: calc(100vh - 205px);
   }
   .shipment-header-search-result-no-actions-no-nav {
      height: calc(100vh - 150px);
   }
}

@media (min-width: 1000px) {
   .shipment {
      /*height: var(--height) !important;*/
      min-height: var(--min-height) !important;
      width: var(--main-width);
   }
}
