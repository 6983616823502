.ad-item-panel {
   visibility: visible;
   overflow: hidden !important;
   width: 100%;
   height: 600px;
   max-height: none;
   left: 0px;
   position: relative;
   display: block;
   text-align: center;
   /* background-image: url("/images/cover/cover-1-small.jpg"); */
}

.ad-item-img {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   width: 100%;
   height: 100%;
   opacity: 1;
   visibility: inherit;
   z-index: 20;
   text-align: center;
}

.ad-item-text {
   position: absolute;
   text-align: center;
   bottom: 120px;
   width: 100%;
   font-family: var(--font-family-bold);
   font-size: 23px;
   font-weight: bolder;
   color: #fff;
   display: block;
   text-shadow: 1px 1px 10px #999, 0 0 25px #333, 0 0 5px #333;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.ad-item-btn-panel {
   margin-left: auto;
   margin-right: auto;
   position: relative !important;
   bottom: 100px !important;
   display: flex;
   flex-direction: row;
   width: 400px !important;
}

.ad-item-btn {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 190px !important;
   font-family: var(--font-family-bold);
   font-size: 16px;
   color: #fff;
   border-radius: 3px !important;
   text-shadow: 0 0 3px #222 !important;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.add-item-icon {
   font-size: 17px;
   position: "relative";
   top: 2px;
   margin-right: 7px;
}

.slide-right {
   width: 100%;
   overflow: hidden;
   margin-left: 300px;
   max-width: 500px;
}

.slide-right span {
   animation: 2s slide-right 2s forwards;
   transform: translateX(-100%);
}

@keyframes slide-right {
   to {
      transform: translateX(0);
   }
}

@media (max-width: 1000px) {
   .ad-item-panel {
      width: 100vw !important;
      height: 300px;
   }
   .ad-item-text {
      font-size: 20px;
      bottom: 120px;
      padding: 0px 5px;
   }
   .ad-item-btn-panel {
      bottom: 30px !important;
      width: 370px !important;
   }
   .ad-item-btn {
      bottom: 75px;
      width: 190px !important;
   }
   .price-check {
      width: 160px !important;
   }
}

@media (min-width: 600px) and (max-width: 1100px) {
   .ad-item-panel {
      height: 450px;
   }
   .ad-item-text {
      font-size: 20px;
      bottom: 120px;
   }
   .ad-item-btn {
      bottom: 75px;
   }
}

@media (min-width: 1000px) {
   .ad-item-text {
      width: 1000px;
      margin-left: calc(50vw - 500px) !important;
   }
}
