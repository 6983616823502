.input-select {
   margin-top: 20px;
   /*height: 42px;*/
   position: relative;
}

.input-select:hover,
.input-select:focus-within {
   color: #0297be !important;
}

.input-select-label-container {
   position: absolute;
   display: flex;
   justify-content: flex-start;
   z-index: 0;
   padding: 0px 10px;
   top: 0px;
}

.input-select-label {
   margin-top: -10px;
   background-color: #fff;
   padding: 0px 5px;
}

.input-select-skeleton {
   margin-top: 20px;
   width: 100%;
   padding: 6px 15px 7px 15px;
}

.input-select-select .ant-select-selector {
   border-radius: 0px !important;
   font-family: var(--font-family-regular);
   font-size: 13px;
   font-weight: 600;
   color: #666 !important;
   height: 42px !important;
   display: flex;
   align-items: center;
}

.shipment-desc-update .input-select-select .ant-select-selector {
   height: 48px !important;
}

.shipment-desc-update .input-select-select .ant-select-selection-search-input {
   height: 36px !important;
}
