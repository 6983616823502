.images-panel {
   margin-top: 5px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-content: flex-start;
}

.images-panel .image {
   object-fit: cover;
   margin-right: 3px;
   margin-bottom: 3px;
   border-radius: 2px;
   /*...Layout.regularBorder;*/
   cursor: pointer;
}

.images-panel .image-sign {
   object-fit: contain;
   margin-right: 3px;
   margin-bottom: 3px;
   border-radius: 2px;
   /*...Layout.regularBorder;*/
   cursor: pointer;
}

.images-panel .delete-button {
   height: 20px;
   width: 20px;
   position: absolute;
   background-color: var(--warning-button);
}
