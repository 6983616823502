.category-info-content {
   display: flex;
   flex-wrap: wrap;
}

.category-info-content-left {
   width: 700px;
   overflow: hidden;
}

.category-info-content-right {
   width: 265px;
   overflow: hidden;
}

.category-info-photos {
   width: 700px;
   padding: 40px 0px;
   margin: auto;
}

.category-info-photos-item {
   width: 350px !important;
   padding: 0px 10px !important;
}

.category-info-photo-image {
   width: 100%;
   border-radius: 4px;
   border: 1px solid #eee;
}

@media (max-width: 1000px) {
   .category-info-content-left {
      width: calc(100vw - 40px);
   }
   .category-info-content-right {
      width: 100%;
      margin: 0px 0px 0px 0px;
   }
   .category-info-photos {
      width: calc(100vw - 40px);
      z-index: 0;
   }
   .category-info-photos-item {
      width: calc(100vw - 40px) !important;
      padding: 0px 5px !important;
   }
}

@media (min-width: 1000px) {
   .category-info-content {
      display: flex;
      width: 1000px !important;
   }
   .category-info-content-right {
      margin: 0px 0px 0px 30px;
   }
}
