.step-images {
   width: 100%;
   height: 100%;

   z-index: 99;
   position: absolute;
   left: 0px;
   top: 0px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;

   background: rgb(0, 0, 0, 0.1);
}

.step-images-container {
   padding: 0px 5px 5px 5px;
}
