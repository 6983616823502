.modal-container {
   position: absolute;
   top: 0px;
   left: 0px;
   /*width: calc(100vw);
   height: 100vh;*/
   width: 100%;
   height: 100%;
   align-items: center;
   justify-content: center;
   background: rgb(0, 0, 0, 0.1);
   z-index: 9;
}

.modal-content {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   /*padding: 10px 10px;*/
   background-color: #fff;
   /*border-radius: 5px;*/
   align-self: center;
}

@media (max-width: 1000px) {
   /*.modal-content {
      width: calc(100vw - 40px);
   }*/
}
