.select-transport-type {
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   min-height: var(--min-height) !important;
}

.select-transport-type-content {
   margin: auto;
   padding: 20px 0px !important;
   overflow: hidden;
}

.select-transport-type-provider {
   padding-top: 15px;
}

.select-transport-type-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-top: 0px;
   padding-bottom: 15px;
}

@media (max-width: 1000px) {
   .select-transport-type {
      overflow: auto;
      height: calc(100vh - 80px);
      margin: 10px 0px;
   }

   .select-transport-type-content {
      padding: 10px 20px !important;
   }

   .select-transport-type-header {
      flex-direction: column;
      align-items: flex-start;
   }

   .select-transport-type-header-label {
      padding-bottom: 5px;
   }

   .select-transport-type-provider {
      padding-top: 10px;
   }
}

@media (min-width: 1000px) {
   .select-transport-type {
      width: var(--main-width) !important;
   }
}
