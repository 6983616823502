.carrier-form-images {
   margin-top: 5px;
   display: flex;
   justify-content: flex-start;
}

.carrier-form-dimension-label {
   padding: 15px 0px 0px 15px;
}

.carrier-form-dimension {
   display: flex;
   justify-content: space-between;
   padding-bottom: 10px;
}
