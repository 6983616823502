.new-shipment {
   margin-left: auto;
   margin-right: auto;
   /*overflow: hidden;*/
   min-height: 750px;
}

.new-shipment-content {
   margin: 20px 0px !important;
   /*overflow: hidden;*/
   height: 100%;
}

.new-shipment-map {
   display: flex;
   flex-direction: column;
   margin-top: 10px;
   height: calc(100vh - 325px);
   min-height: 612px;
}

.new-shipment .new-shipment-options {
   display: flex;
   flex: 1;

   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   margin-top: 5px;
   margin-right: 20px;
}

.new-shipment .new-shipment-calendar-panel {
   display: flex;
   flex-direction: column;
}

.new-shipment-calendar-title {
   margin-top: 5px;
   margin-right: 20px;
   text-align: left;
}

.new-shipment .new-shipment-calendar {
   display: flex;
   flex: 1;
}

.new-shipment .new-shipment-calendar {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   margin-top: 5px;
   margin-right: 20px;
}

.new-shipment .new-shipment-time-panel {
   display: flex;
   flex-direction: column;
   padding: 0px 0px;
}

.new-shipment .new-shipment-time-title {
   margin-top: 5px;
   margin-right: 20px;
   text-align: left;
}

.new-shipment .new-shipment-time-content {
   display: flex;
   height: 45px;
   align-items: center;
}

.new-shipment .new-shipment-time {
   width: 100px;
   margin-right: 10px;
   padding: 7px 6px;
   text-align: center;
}

.new-shipment .new-shipment-weight-panel {
   display: flex;
   flex-direction: column;
   margin-left: 15px;
}

.new-shipment-weight-title {
   margin-top: 5px;
   margin-right: 5px;
   text-align: left;
}

.new-shipment .new-shipment-weight {
   margin-top: 6px;
   margin-right: 0px;
}

.new-shipment .new-shipment-weight input {
   font-size: 13px;
   font-weight: 600;
   color: #555;
}

.new-shipment .new-shipment-weight .ant-input-number {
   width: 70px;
}

.new-shipment .new-shipment-weight .ant-input-number-input {
   padding: 0px 7px;
}

.new-shipment .new-shipment-weight-info-section {
   margin-top: 3px;
   margin-right: 5px;
   display: flex;
   justify-content: space-between;
}

.new-shipment .dot-red {
   margin-top: 15px;
   margin-left: 5px;
   height: 8px;
   width: 8px;
   background-color: #d5392c;
   border-radius: 50%;
   display: inline-block;
}

.new-shipment .dot-blue {
   margin-top: 15px;
   margin-left: 5px;
   height: 8px;
   width: 8px;
   background-color: #0089fd;
   border-radius: 50%;
   display: inline-block;
}

.new-shipment .bottom-panel {
   padding-top: 5px;
   padding-bottom: 5px;
   position: sticky;
   bottom: 0px;
   background-color: #fff;
}

.new-shipment .bottom-content {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin-top: 0px;
}

.new-shipment .bottom-selection {
   display: flex;
   flex-direction: row;
   background-color: #fff;
   height: 90px;
   margin-top: 2px;
   margin-bottom: 4px;
}

.new-shipment .arrow-right {
   font-size: 20px;
   color: #aaa;
}

.new-shipment .bottom-selection-left {
   padding: 10px 5px 10px 5px;
   display: flex;
   flex-direction: column;
   flex: 1;
}

.new-shipment .bottom-selection-right {
   width: 20px;
   background-color: var(--light-blue);
   display: flex;
   align-items: center;
}

.new-shipment .origin-destination {
   overflow-y: scroll;
   height: 95px;
   margin-bottom: 5px;
}

.new-shipment .distance-text {
   margin-left: 5px;
}

.new-shipment .origin-select {
   background-color: var(--primary-button);
   padding: 7px 20px;
   border-radius: 17px;
   margin-top: 5px;
}

.new-shipment .duration-text {
   margin-left: 5px;
}

.new-shipment .distance-duration {
   display: flex;
   justify-content: center;
   flex-direction: row;
   height: 90px;
   margin-top: 2px;
   margin-bottom: 4px;
}

.new-shipment .distance-duration-left {
   padding: 10px 5px 10px 15px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   flex: 1;
}

.new-shipment .distance-duration-right {
   width: 20px;
   background-color: var(--light-blue);
   display: flex;
   align-items: center;
}

.new-shipment .distance-duration-content {
   display: flex;
   flex-direction: row;
   padding-bottom: 5px;
}

.new-shipment .distance-duration-label {
   flex: 1;
}

.new-shipment .distance-duration-text {
   padding-right: 10px;
}

@media (max-width: 1000px) {
   .new-shipment-content {
      padding: 0px 20px 0px 20px !important;
      height: calc(100vh - 80px) !important;
      min-height: 820px;
   }

   .new-shipment .new-shipment-options {
      justify-content: space-between;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: 0px;
      margin-bottom: 10px;
      flex-wrap: wrap;
      flex-direction: row;
   }

   .new-shipment .new-shipment-calendar-panel {
      display: flex;
      padding: 0px 10px;
   }

   .new-shipment-calendar-title {
      text-align: left;
      margin-top: 0px;
   }

   .new-shipment .new-shipment-time-content {
      height: 42px;
   }

   .new-shipment .new-shipment-time-title {
      margin-top: 0px;
   }

   .new-shipment .new-shipment-calendar {
      justify-content: space-between;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: 4px;
      margin-bottom: 5px;
      overflow: hidden;
   }

   .new-shipment .new-shipment-time-panel {
      padding: 0px 10px 0px 0px;
   }

   .new-shipment .new-shipment-time {
      width: 90px;
      margin-right: 5px;
      padding: 7px 5px;
   }

   .new-shipment .new-shipment-weight-panel {
      flex-direction: row;
      margin-left: 10px;
   }

   .new-shipment-weight-title {
      margin-top: 6px;
      margin-right: 5px;
      text-align: left;
   }

   .new-shipment .new-shipment-weight-info-section {
      margin-left: 5px;
   }

   .new-shipment .new-shipment-weight {
      margin-top: 0px;
      margin-left: 5px;
   }

   .new-shipment-weight-info {
      margin-top: 6px;
      margin-left: 5px;
      text-align: left;
   }
}

@media (min-width: 1000px) {
   .new-shipment {
      width: var(--main-width) !important;
   }
   .new-shipment-calendar {
      justify-content: flex-end;
   }
}
