.confirm-shipment {
   width: 100%;
   display: relative;
   margin-left: auto;
   margin-right: auto;
   /*overflow: hidden;*/
   /* height: calc(100vh - 90px) !important; */
   min-height: 850px;
}

.confirm-shipment-content {
   margin: 20px 0px !important;
   /*overflow: hidden;*/
   height: 100%;
}

.confirm-shipment-map {
   display: flex;
   flex-direction: column;
   margin-top: 10px;
   width: val(--main-width);
   height: calc(100vh - 530px) !important;
   min-height: 500px;
}

.confirm-shipment .confirm-shipment-calendar-panel {
   flex: 1;
   display: flex;
   flex-direction: column;
}

.confirm-shipment-calendar-title {
   margin-top: 10px;
   margin-right: 20px;
   text-align: right;
}

.confirm-shipment .confirm-shipment-calendar {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   margin-top: 5px;
   margin-right: 20px;
}

.confirm-shipment .bottom-panel {
   margin-top: 5px;
   width: val(--main-width);
}

.confirm-shipment .bottom-content {
   background-color: #fff;
   margin-top: 5px;
}
.confirm-shipment .bottom-selection {
   display: flex;
   padding: 15px;
   background-color: #fff;
}
.confirm-shipment .origin-text {
   flex: 1;
}
.confirm-shipment .distance-text {
   margin-left: 5px;
   /*...Font.fontFamilyBold;
  ...Font.fontSizeBold;
  ...Font.fontColorPurple;*/
}
.confirm-shipment .destination-text {
   flex: 1;
   /*...Font.bigFont;*/
}
.confirm-shipment .duration-text {
   margin-left: 5px;
   /*...Font.fontFamilyBold;
  ...Font.fontSizeBold;
  ...Font.fontColorPurple;*/
}

/*******************************/

.confirm-shipment .content {
   padding: 5px 0px;
   margin: 0px 10px;
   border-radius: 5px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: row;
   background-color: #ffffff;
   /*...Layout.regularBorder;*/
}

.confirm-shipment .section {
   display: flex;
   width: calc((100%) / 4);
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 5px 5px 5px 0px;
}

.confirm-shipment .section-icon {
   display: flex;
   width: 30px;
   height: 30px;
   background-color: #95a3d2;
   align-items: center;
   justify-content: center;
   border-radius: 15px;
   color: #fff;
   /*...Font.fontColorWhite;*/
}

.confirm-shipment .section-content {
   flex: 1;
   margin-left: 10px;
}

.confirm-shipment .section-title {
   flex-wrap: wrap;
   /*...Font.subRegularGreyFont;*/
}

.confirm-shipment .section-layout {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   /*...Font.subRegularGreyFont;*/
}

.confirm-shipment .section-info {
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

@media (max-width: 1000px) {
   .confirm-shipment {
      /*min-height: calc(100vh - 70px) !important;*/
      min-height: 1100px;
   }

   .confirm-shipment-content {
      padding: 0px 20px !important;
   }

   .confirm-shipment .section {
      width: calc((100%) / 2);
   }

   .confirm-shipment .confirm-shipment-calendar-panel {
      display: flex;
   }

   .confirm-shipment-calendar-title {
      margin-left: 20px;
      text-align: left;
   }

   .confirm-shipment .confirm-shipment-calendar {
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: 10px;
      margin-bottom: 10px;
   }

   .confirm-shipment-map {
      /*min-height: 850px;
      min-height: calc(100vh - 465px) !important;*/
   }
   .confirm-shipment .bottom-content {
      position: sticky;
      bottom: 0px;
      padding: 5px 0px;
   }

   .confirm-shipment .section-layout {
      flex-direction: column;
   }
}

@media (min-width: 1000px) {
   .confirm-shipment {
      width: 1000px !important;
   }
}
