.origin-destination-container {
   margin-right: 5px;
   width: 160px;
}

.origin-destination-place {
   display: flex;
   flex-direction: row;
   justify-items: flex-end;
}

.origin-destination-circle {
   position: relative;
   width: 20px;
   min-height: 30px;
}

.origin-destination-line-top {
   border-left-style: solid;
   border-left-color: var(--purple-dark);
   border-left-width: 1px;
   width: 1px;
   height: 15px;

   position: absolute;
   bottom: -10px;
   left: 50%;
   transform: translate(0%, -50%);
}

.origin-destination-line-bottom {
   border-left-style: solid;
   border-left-color: var(--purple-dark);
   border-left-width: 1px;
   width: 1px;
   height: 15px;

   position: absolute;
   top: 2px;
   left: 50%;
   transform: translate(0%, -50%);
}

.origin-destination-circle-outline {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   display: flex;
   border: 1px solid var(--purple-dark);
   background-color: #fff;
   border-radius: 10px;
   width: 20px;
   height: 20px;
   justify-content: space-around;
   align-items: center;
   z-index: 0;
}

.origin-destination-circle-inline {
   width: 8px;
   height: 8px;
   background-color: var(--purple-regular);
   border-radius: 4px;
}

.origin-destination-text {
   flex: 1;
   display: flex;
   flex-wrap: wrap;
   padding-left: 10px;
   justify-items: center;
   align-items: center;
   /*...Font.regularFont;*/
}

.origin-destination-line {
   height: 0px;
   margin-left: 10px;
   border-left-style: solid;
   border-left-color: var(--purple-dark);
   border-left-width: 1px;
}

@media (max-width: 1000px) {
}
