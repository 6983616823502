.showcase-item-content {
   width: 100%;
   overflow: hidden;
   margin: auto;
   position: absolute;
   top: 500px;
}

.showcase-item-text {
   padding: 15px 20px;
   position: relative;
   text-align: right;
   font-family: var(--font-family-bold);
   font-size: 20px;
   font-weight: bolder;
   color: #fff;
   display: block;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
   background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(2, 151, 190, 1) 100%);
}

.showcase-item-desc {
   width: 960px;
   margin-left: auto;
   margin-right: auto;
}

@media (max-width: 1000px) {
   .showcase-item-content {
      width: 100%;
      overflow: hidden;
      margin: auto;
      height: 240px;
      top: -240px;
      position: relative;
   }
   .showcase-item-text {
      top: 175px;
      font-size: 18px;
      padding: 5px 20px;
   }
   .showcase-item-desc {
      width: 100%;
   }
}

@media (min-width: 600px) and (max-width: 1100px) {
   .showcase-item-text {
      font-size: 20px;
      bottom: 120px;
   }
}
