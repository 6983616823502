.shipment-desc-update {
   display: flex;
   flex-direction: column;
   border-radius: 2px;
   padding: 0px 5px;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   width: 100%;
   background-color: #fff;
}

.shipment-desc-update .sect {
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: wrap;
   padding: 5px 5px;
}

.shipment-desc-update .sect-icon-container {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #95a3d2;
   color: #fff;
   height: 30px;
   width: 30px;
   border-radius: 15px;
   font-size: 10px;
   margin-right: 5px;
   /*...Font.fontColorWhite;*/
}

.shipment-desc-update .sect-icon {
   font-size: 17px;
}

.shipment-desc-update .sect-content {
   /* flex: 1; */
   width: calc((100% - 15px) / 3);
   padding-bottom: 10px;
   margin-right: 5px;
}

.shipment-desc-update .sect-content-top {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.shipment-desc-update .sect-content-text-area {
   margin-top: 5px;
   border: 1px solid #eee;
   width: 100%;
}

.shipment-desc-update .image-upload .ant-upload-list {
   flex-wrap: wrap;
}

.shipment-desc-update .ant-upload-picture-card-wrapper {
   width: 320px;
}

.shipment-desc-update .ant-upload-list-picture-card-container,
.shipment-desc-update .ant-upload.ant-upload-select-picture-card {
   width: 98px;
   height: 98px;
}

.shipment-desc-update .sect-title {
   flex-wrap: wrap;
   /*...Font.subRegularFont;*/
}

.shipment-desc-update .sect-title-info {
   flex: 1;
   display: flex;
   justify-content: space-between;
}

.shipment-desc-update .sect-info {
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.shipment-desc-update .sect-address {
   flex-wrap: wrap;
   margin: 5px 0px;
   padding: 5px 10px 5px 10px;
   border: 1px dashed #ccc;
   background-color: #fafafa;
}

.shipment-desc-update .sect-date-time {
   margin-left: 10px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.shipment-desc-update .text-input {
   height: 50px;
   margin-top: 5px;
   padding: 5px;
   border-radius: 2;
   flex-wrap: wrap;
   align-items: flex-start;
   /*...Font.regularFont;
  ...Layout.regularBorder;*/
}

.shipment-desc-update .image-upload-icon svg {
   color: var(--primary-button);
}

.shipment-desc-update .image-upload-text {
   color: var(--primary-button);
}

.shipment-desc-update .ant-select-selection-search-input {
   margin-top: 4px !important;
   font-size: 13px !important;
   font-weight: 600 !important;
   padding: 0px 0px 0px 0px !important;
}

.shipment-desc-update .sect-border:hover {
   border: 1px solid var(--tint-color) !important;
}

.shipment-desc-update .sect-border:focus-within {
   box-shadow: 0 0 0 2px var(--focus-color) !important;
}

.shipment-desc-update .input-select {
   margin-top: 5px;
}

.shipment-desc-update .input-select-select .ant-select-selector {
   width: 320px !important;
}

@media (max-width: 1000px) {
   .shipment-desc-update .sect-content {
      /* flex: 1; */
      width: calc(100%);
      padding-top: 5px;
      padding-bottom: 5px;
   }

   .shipment-desc-update .sect-address {
      min-height: 48px;
   }

   .shipment-desc-update .input-select-select .ant-select-selector {
      width: calc(100vw - 67px) !important;
   }

   .shipment-desc-update .ant-upload-picture-card-wrapper {
      margin-right: 10px;
      width: calc(100vw - 55px);
   }

   .shipment-desc-update .image-upload .ant-upload-list {
      flex-wrap: wrap;
   }

   .shipment-desc-update .ant-upload-list-picture-card-container,
   .shipment-desc-update .ant-upload.ant-upload-select-picture-card {
      width: 90px;
      height: 90px;
   }
}

@media (min-width: 1000px) {
   .shipment-desc-update .sect-address {
      height: 48px;
      overflow: auto;
   }
}
