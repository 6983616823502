.booking {
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   height: var(--height) !important;
   min-height: var(--min-height) !important;
}

.booking-content {
   margin: auto;
   padding: 20px 0px 0px 0px !important;
   margin-bottom: 20px;
   overflow: hidden;
}

@media (max-width: 1000px) {
   .booking {
      /*min-height: var(--min-height-mobile) !important;*/
      height: calc(100vh - 120px) !important;
   }

   .booking-content {
      padding: 20px 0px 0px 0px !important;
   }

   .booking-content-steps {
      padding: 0px 20px 20px 20px;
   }
}

@media (min-width: 1000px) {
   .booking {
      width: var(--main-width) !important;
   }
}
