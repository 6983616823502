.bar-analytic-container {
  display: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 0px;
  /* padding: 20px 0px; */
  align-items: center;
  justify-content: center;
}

.bar-analytic-page-view {
  align-self: stretch;
  padding: 0px 5px;
  margin-bottom: 10px;
}

.bar-analytic-content {
  text-align: center;
}

.bar-analytic-content h2 {
  margin: 0px 20px;
}

.kpi-wrapper {
  display: flex;
  flex-direction: row;
}

.parameter-wrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.parameter-title {
  /* margin: 20px 0px; */
  margin-top: 20px;
}

.parameter-metric {
  display: flex;
  flex-direction: column;
}

.parameter-output {
  /* margin: 20px 0px; */
  /* margin-top: 20px; */
}

.parameter-breakdown {
  /* margin: 20px 0px; */
  margin-top: 20px;
}

.parameter-input {
  /* margin: 20px 0px; */
  margin-top: 20px;
}

.parameter-period {
  /* margin: 20px 0px; */
  margin-top: 20px;
}

.parameter-button {
  margin-top: 20px;
}

.chart-wrapper {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
}

.chart-parameter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.chart-parameter-period {

}

.chart-parameter-startdate {

}

.chart-parameter-enddate {

}

.chart-parameter-button {
  
}

.chart-display {
  display: flex;
  flex-direction: row;
}

.chart-display-recharts {

}

.chart-display-growth {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  text-align: left;
}

.chart-display-growth-total {
  display: flex;
  flex-direction: column;
}

.output-growth-total {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-growth-total {
  display: flex;
  flex-direction: column;
}

.chart-display-growth-period {

}

.chart-indicators {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.chart-indicators th.periodhead, td.periodcell {
  border: 1px solid lightgray;
  border-collapse: collapse;
}

.chart-indicators th.periodhead {
  text-align: center;
}

.chart-indicators td.periodcell {
  padding: 5px;
  text-align: left;
  font-size: 13px;
}

.ant-select {
  /* width: 200px; */
}

@media (max-width: 1000px) {
  .bar-analytic-container {
     padding: 20px 0px;
     height: calc(100vh - 140px) !important;
     overflow: auto;
     margin-top: 10px;
     margin-bottom: 10px;
     width: 100%;
  }

  .recharts-responsive-container {
     margin: 0px;
     align-items: center;
     justify-content: center;
     width: 100%;
  }

  .bar-analytic-content .title {
     margin-bottom: 0px;
  }
}

@media (min-width: 1000px) {
  .bar-analytic-container {
     width: var(--main-width);
     padding: 0px;
     min-height: 700px !important;
  }
}