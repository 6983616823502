.fee-view {
   width: 400px;
}

.fee-view-content {
   padding: 15px 15px;
}

.fee-view-item {
   display: flex;
   padding: 0px 0px 10px 0px;
}

.fee-view-item-desc {
   flex: 1;
}

.fee-view-item-subtotal {
   display: flex;
}

.fee-view-promotion {
   color: var(--yellow) !important;
}

.fee-view-total {
   display: flex;
   padding: 10px 0px 5px 0px;
}

@media (max-width: 1000px) {
   .fee-view {
      width: calc(100vw - 40px);
   }
}

@media (min-width: 1000px) {
}
