.custom-calendar {
   width: 325px;
}

.custom-calendar-picker {
   position: absolute;
   z-index: 99;
   width: 270px;
   top: 255px;
}

.custom-calendar-date {
   margin-right: 5px;
   padding: 7px 5px;
   width: 100px;
   text-align: center;
}

.custom-calendar-picker-center {
   position: absolute;
   z-index: 99;
   width: 270px;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.custom-calendar-container {
   align-items: flex-start;
   justify-content: flex-start;
   flex-direction: row;
   display: flex;
}

.custom-calendar-button {
   align-items: center;
   justify-content: center;
   margin-right: 4px;
}

.custom-calendar-background {
   width: 34px;
   height: 34px;
   border-radius: 17px;
   border-color: #ddd;
   border-width: 1px;
   border-style: solid;
   align-items: center;
   justify-content: center;
}

.react-calendar__navigation__label {
   font: 13px "Poppins-Bold" !important;
   color: #666 !important;
}

.react-calendar__month-view__weekdays__weekday {
   font: 12px "Poppins-Bold" !important;
   color: #fff !important;
   background: #8393ca;
   text-transform: initial;
}

.react-calendar__month-view__weekdays__weekday abbr {
   text-decoration: none !important;
}

.react-calendar__month-view__days__day {
   font: 12px "Poppins-Bold" !important;
}

.react-calendar__tile--now {
   background: #3198c1 !important;
   color: white !important;
   border-radius: 20px;
}

.react-calendar__tile--active {
   background: #8393ca !important;
   color: white !important;
   border-radius: 20px;
}

.react-calendar__tile {
   border: 2px solid #fff !important;
   border-radius: 20px;
}

.react-calendar__navigation {
   margin-bottom: 0px !important;
}

.react-calendar__navigation__label {
   background: #eee !important;
}

.react-calendar__navigation__arrow {
   background: #eee !important;
}

.react-calendar__month-view__days__day--weekend {
   color: #e76fa6;
}

@media (max-width: 1000px) {
   .custom-calendar-picker {
      top: calc((100vh - 270px) / 2);
      left: calc((100vw - 270px) / 2);
   }
   .custom-calendar {
      width: 250px;
   }

   .custom-calendar-week {
      width: 100%;
   }
}
