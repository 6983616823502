@import "../../../App.css";

.transporters {
   display: flex;
   flex-direction: column;
   padding: 5px 0px 25px 0px;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
}

.transporters-header {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 15px;
   flex-wrap: wrap;
}

.transporters-header-search {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   flex: 1;
}

.transporters-header-button {
   display: flex;
   justify-content: flex-end;
}

.transporters-header-search-section {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   padding: 5px 10px 5px 0px;
}

.transporters-header-search-icon {
   margin-right: 5px;
}

.transporters-header-search-input {
   display: flex;
   align-items: center;
}

.transporters-header-search-select {
   flex: 1;
}

@media (max-width: 1000px) {
   .transporters {
      min-height: var(--min-height-mobile) !important;
      padding: 10px 0px 0px 0px;
      width: 100%;
   }
   .transporters-header {
      padding: 0px 20px 10px 20px;
   }
   .transporters-header-search {
      min-width: calc(100vw - 40px);
   }
   .transporters-header-search-section {
      padding: 5px 0px;
      width: 100%;
   }
   .transporters-header-search-input {
      flex: 1;
   }
   .transporters-header-button {
      padding-top: 5px;
      flex: 1;
   }
   .transporters .search-result {
      margin-top: 10px;
      height: calc(100vh - 250px) !important;
      padding: 0px 20px;
      overflow: auto;
   }
   .transporters .search-result-no-nav {
      height: calc(100vh - 195px) !important;
   }
   .transporters .search-result-admin {
      height: calc(100vh - 295px) !important;
   }
   .transporters .search-result-admin-no-nav {
      height: calc(100vh - 265px) !important;
   }
}

@media (min-width: 1000px) {
   .transporters {
      height: var(--height) !important;
      min-height: var(--min-height) !important;
      width: var(--main-width);
   }
   .transporters-header-search-select {
      width: 250px !important;
   }
}
