.select-profile {
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
   min-height: var(--min-height) !important;
   height: var(--height);
   display: flex;
}

.select-profile-content {
   width: var(--popup-width);
   margin: auto;
   padding: 0px 0px 50px 0px !important;
   overflow: hidden;
}

.select-profile-header {
   margin: 20px 0px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 15px 15px;
   background-color: var(--light-blue);
}

@media (max-width: 1000px) {
   .select-profile {
      min-height: 600px !important;
      height: var(--height-mobile);
   }

   .select-profile-content {
      padding: 20px 20px !important;
      width: 100%;
   }
}

@media (min-width: 1000px) {
   .select-profile {
      width: var(--main-width) !important;
   }
}
