.voucher-view-item {
   width: 480px;
   padding: 0px 0px 15px 0px;
   margin-bottom: 15px;
   /*...Layout.regularBottomBorder;*/
}

.voucher-view-content {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.voucher-view-desc {
   flex: 1;
   margin-left: 0px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.voucher-view-provider {
   margin-top: 5px;
}

.voucher-view-provider-info {
   padding: 3px 8px;
   background-color: var(--purple-regular);
   border-radius: 2px;
}

@media (max-width: 1000px) {
   .voucher-view-item {
      width: calc(100vw - 20px);
   }
}

@media (min-width: 1000px) {
}
