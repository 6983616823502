.select-transporter {
   width: var(--popup-width);
   height: calc(100vh - 180px);
   z-index: 99;
   position: absolute;
   display: flex;
   flex-direction: row;
   align-items: center;

   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

.select-transporter-container {
   height: 650px;
   flex: 1;
   align-items: stretch;
   justify-content: center;
   border-radius: 3px;
   background-color: #fff;
   margin: auto;
   margin-top: auto;
   margin-bottom: auto;
}

.select-transporter-search {
   padding: 0px;
   height: 608px;
   background-color: #fff;
}

.select-transporter-search-input {
   padding: 10px 10px 0px 10px;
   height: 50px;
}

.select-transporter-search-result {
   align-self: stretch;
   padding: 5px 0px 10px 10px;
   max-height: calc(100% - 65px);
   overflow: auto;
}

.select-transporter input:hover,
.select-transporter input:active {
   border: 0px solid #fff !important;
}

@media (max-width: 1000px) {
   .select-transporter {
      width: calc(100vw);
      height: calc(100vh);

      left: 0%;
      top: 0px;
      transform: translate(0%, 0%);
   }

   .select-transporter-container {
      height: 100%;
   }

   .select-transporter-search {
      height: calc(100vh - 40px);
   }

   .select-transporter-search-result {
      max-height: calc(100vh - 220px);
      padding-right: 0px;
   }
}
