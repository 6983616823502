/*.footer-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}*/

.footer-item {
   width: 200px;
   display: block;
   flex-wrap: wrap;
   border-radius: 4px;
   overflow: hidden;
   margin-bottom: 0px;
   text-align: left;
}

.footer-item-top {
   padding: 10px 0px;
   font-family: var(--font-family-regular);
   font-weight: 600;
   font-size: 14px;
   color: #fff;
   border: 1px solid #ddd;
   margin: 0px 50px 10px 0px;
   padding: 10px 10px;
   border-top-left-radius: 10px;
   border-bottom-right-radius: 10px;
   background-color: #0294bb;
}

.footer-item-link {
   display: block;
   padding: 5px 10px;
   font-size: 14px;
   font-weight: 600;
   color: #555;
}

.footer-item-icon {
   padding: 10px 0px 5px 10px;
   font-size: 30px;
   color: #555;
}

.footer-item .instagram {
   color: #e83e8c;
}

.footer-item .facebook {
   color: #4267b2;
}

.footer-item-link:hover,
.footer-item-icon:hover {
   color: #222;
}

@media (max-width: 1000px) {
   .footer-item {
      width: calc((100vw - 40px) / 2);
   }
}
