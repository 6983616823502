@import '../../App.css';

.admin {
   display: flex;
   flex-direction: column;
   padding: 25px 0px;
   margin-left: auto;
   margin-right: auto;
   overflow: hidden;
}

@media (max-width: 1000px) {
   .admin {
      min-height: var(--min-height-mobile) !important;
      padding: 20px 20px;
   }
}

@media (min-width: 1000px) {
   .admin {
      height: var(--height) !important;
      min-height: var(--min-height) !important;
      width: var(--main-width);
   }
}
