.input-text {
   margin-top: 20px;
   min-height: 42px !important;
   position: relative;
}

.input-text:hover,
.input-text:focus-within {
   color: #0297be !important;
}

.input-text-label-container {
   position: absolute;
   display: flex;
   justify-content: flex-start;
   width: 100%;
   padding: 0px 10px;
   top: 0px;
}

.input-text-label {
   margin-top: -10px;
   background-color: #fff;
   padding: 0px 5px;
}

.input-text-skeleton {
   margin-top: 20px;
   width: 100%;
   padding: 8px 15px 8px 15px;
}
