.profile-view-item {
   width: 480px;
   padding: 0px 0px 10px 0px;
   margin-bottom: 15px;
   /*...Layout.regularBottomBorder;*/
}

.select-profile .profile-view-item {
   width: 100%;
}

.profile-view-content {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.profile-view-pic-image {
   width: 60px;
   height: 60px;
   border-radius: 30px;
   border: 1px solid #eee;
   object-fit: cover;
   background-color: #eee;
}

.profile-view-pic {
   flex: 1;
   margin-left: 10px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

@media (max-width: 1000px) {
}

@media (min-width: 1000px) {
}
