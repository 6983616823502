:root {
   --main-width: 1000px;
   --primary: #3198c1;
   --header-color: var(--primary); /* #02a7be; #6785c3; #0297be; #0296bb; */
   --tint-color: #3198c1; /* #6785c3; #2f95dc; */
   --modal-color: #000;
   --border-color: #e5e5e5;
   --dark-border-color: #ccc;
   --border-form-color: #d9d9d9;
   --focus-color: #3198c125;

   --red: #d5392c;
   --red-medium: #e9968f;
   --red-light: #eca39d;
   --yellow: #f69f44;
   --green: #02a29b;
   --purple-dark: #8393ca;
   --purple-regular: #8f9dcf;
   --purple-light: #b9c2e1;
   --purple-super-light: #d1d7eb;
   --blue-dark: #60b3f6;
   --blue: #90caf9;
   --light-blue: #e5f9ff;
   --super-light-blue: #f6fdff;
   --grey: #ccc;

   --primary-button: #02a29b; /* #0297be; #02a29b; */
   --primary-hover-button: #02b3ab;
   --primary-active-button: #02918b;
   --secondary-button: #8393ca;
   --secondary-hover-button: #8f9dcf;
   --secondary-active-button: #7789c5;
   --warning-button: #d5392c;
   --warning-hover-button: #d8463a;
   --warning-active-button: #c83428;
   --navigate-button: #3198c1;
   --navigate-hover-button: #37a2cc;
   --navigate-active-button: #2e8db3;
   --page-button: #ccc;
   --page-hover-button: #d4d4d4;
   --page-active-button: #c4c4c4;
   --cancel-button: #3198c1;
   --cancel-hover-button: #37a2cc;
   --cancel-active-button: #2e8db3;
   --loading-button: #eee;

   --menu-bottom-regular: #999;
   --menu-bottom-regular-active: #3198c1;

   --notif-red: #d5392c;
   --notif-yellow: #f69f44;
   --notif-green: #02a29b;
   --notif-purple: #8393ca;

   --alert-error: #d5392c;
   --alert-warning: #f69f44;
   --alert-info: #02a29b;

   --alert-error-border: #d5392c88;
   --alert-warning-border: #f69f4488;
   --alert-info-border: #15885a88;

   --highlight-error: #d5392c;
   --highlight-warning: #fce2c6;

   --highlight-warning-border: #f69f44;

   --regular-background: #fff;
   --tab-background: var(--lightBlue);
}
