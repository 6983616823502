.search-input-container {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   background-color: #fff;
   border-radius: 3px;
   padding-left: 2px;
   border: 1px solid var(--border-form-color) !important;
   /*...Layout.regularBorder;*/
}

.search-input-clear-btn {
   padding: 5px;
   margin-right: 5px;
   border-radius: 2px;
   background-color: #fff;
   border-top-right-radius: 20px;
   border-bottom-right-radius: 20px;
}

.search-input-image {
   height: 18px;
   width: 18px;
   object-fit: cover;
   align-items: center;
   justify-content: center;
}
