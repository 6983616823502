.provider-view-item {
   width: 480px;
   padding: 0px 0px 10px 0px;
   margin-bottom: 15px;
   /*...Layout.regularBottomBorder;*/
}

.provider-view-item-full {
   padding: 0px 0px 10px 0px;
   margin-bottom: 15px;
}

.provider-view-content {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.provider-view-pic-image {
   width: 70px;
   height: 70px;
   border-radius: 35px;
   border: 1px solid #eee;
   object-fit: cover;
   background-color: #eee;
}

.provider-view-pic {
   flex: 1;
   margin-left: 10px;
   flex-wrap: wrap;
   /*...Font.regularFont;*/
}

.provider-view-content .section-info-rating {
   display: flex;
   flex-direction: row;
   padding-top: 5px;
}

@media (max-width: 1000px) {
   .provider-view-item {
      width: 460px;
   }
}

@media (max-width: 445px) {
   .provider-view-content .section-info-rating {
      flex-direction: column;
   }
}

@media (min-width: 1000px) {
}
