.service-provider-view {
   padding: 20px 0px;
}

.service-provider-view-top {
   display: flex;
   flex: 1;
   flex-direction: row;
   align-items: flex-start;
   background-color: #ffffff00;
}

.service-provider-view-image {
   width: 75px;
   height: 75px;
   object-fit: cover;
   border-color: #ddd;
   border-width: 0.5px;
   border-radius: 45px;
}

.service-provider-view-content {
   display: flex;
   flex-direction: column;
   padding-left: 10;
}

.service-provider-view-name {
   display: flex;
   flex-wrap: wrap;
}

.service-provider-view-address {
   display: flex;
   margin-top: 0px;
   flex-wrap: wrap;
}

.service-provider-view-bottom {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.service-provider-view-rating-content {
   margin: 0px 10px;
}

.service-provider-view-title {
   margin-top: 5px;
}

.service-provider-view-desc {
   margin-top: 0px;
   width: 200px;
}

.service-provider-view-desc .before-disc {
   color: var(--red-medium);
   text-decoration: line-through;
   padding-right: 5px;
}

.service-provider-view-status {
   margin-top: 15px;
   padding: 2px 8px;
   background-color: var(--yellow);
   border-radius: 2px;
}

@media (max-width: 1000px) {
   .service-provider-view {
      width: calc(100vw - 40px);
   }
   .service-provider-view-desc {
      width: 100px;
   }
   .service-provider-view-content {
      flex: 1;
   }
}

@media (min-width: 1000px) {
   .service-provider-view-content {
      width: 375px;
   }
}
