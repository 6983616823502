.form-layout {
   display: flex;
   margin-left: auto;
   margin-right: auto;
   flex-wrap: wrap;
   /*overflow: hidden;*/
   /*min-height: var(--min-height) !important;*/
   justify-content: center;
   align-items: center;
   padding: 50px 0px;
}

.form-layout .profile-picture {
   margin-top: 20px;
}

.form-layout .ant-select-selection-search-input {
   margin-top: 0px !important;
   font-size: 14px !important;
   font-weight: 600 !important;
   padding: 0px 0px 0px 3px !important;
}

.form-layout .ant-select-selection-item {
   padding: 0px 0px 0px 3px !important;
}

.form-layout .ant-picker {
   border: 1px solid #d9d9d9 !important;
   height: 42px !important;
}

.form-layout .input-select-label-container {
   width: 300px;
}

.form-layout .input-select-select .ant-select-selector {
   width: 395px !important;
   max-width: calc(100vw - 45px) !important;
}

.form-layout-left {
   display: flex;
   width: 300px;
   padding: 0px 0px;
   overflow: hidden;
   justify-content: center;
   align-items: center;
}

.form-layout-right {
   width: calc(100vw - 40px);
   max-width: 400px;
   overflow: hidden;
   margin: auto;
}

.form-layout .doodle {
   height: 300px;
   width: 300px;
   object-fit: cover;
}

.form-layout .title {
   font-family: var(--font-family-bold);
   font-size: 22px;
}

.form-layout .desc {
   padding-bottom: 15px;
   font-weight: 600;
   font-size: 15px;
}

.form-layout form {
   margin: auto;
   overflow: hidden;
   padding: 0px 2px !important;
}

.form-layout form input,
.form-layout form textarea {
   display: block;
   margin-left: auto !important;
   margin-right: auto !important;
   margin-top: 15px;
   overflow: hidden;

   border: 1px solid var(--border-form-color) !important;
   padding: 8px 15px;
   height: 40px !important;
}

.form-layout form .ant-select-selector input,
.form-layout form .ant-picker input {
   border: 0px !important;
}

.form-layout form .ant-input-focused {
   color: #888 !important;
}

.form-layout form .ant-input:hover {
   border: 1px solid var(--tint-color) !important;
}

.form-layout form .ant-input:focus-within {
   box-shadow: 0 0 0 2px var(--focus-color) !important;
}

.form-layout form .ant-input-number {
   width: 395px !important;
   max-width: calc(100vw - 45px) !important;
   height: 40px !important;
   border: 0px;
}

.form-layout form .ant-input-number input {
   margin-top: 0px !important;
   font-size: 14px !important;
   font-weight: 600 !important;
}

.form-layout form button {
   font-size: 14px;
   line-height: 14px;
   font-family: var(--font-family-bold);
   padding: 14px 24px;
   height: 42px;
   display: block;
}

.form-layout-agree-tnc {
   padding-top: 10px;
   padding-bottom: 10px;
   font-size: 14px !important;
   font-weight: 600;
}

.form-layout .error {
   padding: 10px 0px 0px 0px;
   font-size: 13px;
   font-weight: 600;
   color: #f57;
}

.form-layout .submit {
   width: 135px;
}

.form-layout .action-button {
   margin-top: 20px !important;
   display: flex;
}

.form-layout .action-button button {
   font-size: 14px;
   line-height: 14px;
   font-family: var(--font-family-bold);
   padding: 13px 24px;
   height: 42px;
   width: 100px;
   margin-right: 10px;
}

@media (max-width: 1000px) {
   .form-container {
      height: calc(100vh - 80px) !important;
      overflow: auto;
      margin: 10px 0px;
      background-color: #fff;
   }

   .form-layout {
      flex-direction: column;
      padding: 10px 0px;
   }

   .form-layout-left {
      padding-top: 0px;
      height: 250px !important;
      display: none;
   }

   .form-layout-right {
      flex: 1;
   }

   .form-layout .doodle {
      height: 250px;
      width: 250px;
      object-fit: cover;
   }
}
@media (min-width: 1000px) {
   .form-layout {
      /*height: var(--height) !important;*/
      min-height: var(--min-height) !important;
      width: 725px;
   }
}
