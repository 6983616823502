.track-position {
   height: calc(100vh - 160px);
   position: absolute;
   z-index: 9;
   top: 158px;
   display: flex;
   background: #fff;
}

.track-position .container {
   width: var(--main-width);
   top: 0px;
   /*height: calc(100vh - 210px);*/
   height: calc(100vh - 300px);
   background-color: #bbb;
   align-items: center;
   justify-content: center;
   margin-left: auto;
   margin-right: auto;
   position: relative;
}

.track-position .container .info {
   display: flex;
   flex-wrap: wrap;
   min-height: 60px;
   padding-top: 10px;
}

.track-position .last-update {
   display: flex;
   justify-content: flex-end;
   flex: 1;
}

.track-position .last-update-title {
   margin-right: 10px;
}

.track-position .container .options {
   display: flex;
   flex-wrap: wrap;
   min-height: 30px;
   padding-top: 10px;
}

.track-position .container .destination {
   width: 40%;
}

.track-position .container .distance,
.track-position .container .duration,
.track-position .container .eta {
   width: 20%;
}

@media (max-width: 1000px) {
   .track-position {
      position: absolute;
      height: calc(100vh + 2px);
      width: calc(100vw + 2px);
      top: -1px;
      left: -1px;
   }

   .track-position .container {
      height: calc(100vh - 250px);
   }

   .track-position .container .info {
      display: flex;
      flex-wrap: wrap;
      min-height: 60px;
      padding: 10px 10px;
   }

   .track-position .last-update {
      padding: 10px 5px 0px 0px;
      justify-content: space-between;
   }

   .track-position .container .options {
      padding: 10px 10px 0px 10px;
   }

   .track-position .container .destination {
      width: 100%;
      padding-bottom: 5px;
   }
   .track-position .container .distance {
      width: 25%;
   }
   .track-position .container .duration {
      width: 35%;
   }
   .track-position .container .eta {
      width: 40%;
   }
}
