.category-story {
   display: relative;
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   overflow: hidden;
}

@media (max-width: 1000px) {
   .category-story {
      padding: 20px 0px 0px 0px;
   }
}

@media (min-width: 1000px) {
   .category-story {
      padding: 0px 0px 0px 30px;
      border-left: 1px solid #eee;
   }
}
