@import "~antd/dist/antd.css";
@import "./styles/Color.css";
@import "./Theme.css";

:root {
   --main-width: 1000px;
   --popup-width: 500px;
   --footer-height: 330px;
   /*--footer-height-mobile: 320px;*/
   --footer-height-mobile: 120px;
   --min-height: 650px;
   --min-height-mobile: 550px;
   --min-height-content: 600px;
   --min-height-content-mobile: 400px;
   --height: calc(100vh - var(--footer-height));
   --height-mobile: calc(100vh - var(--footer-height-mobile));
   --height-content: calc(100vh - var(--footer-height) - 190px);
   --height-content-mobile: calc(100vh - var(--footer-height-mobile) - 190px);
}

@font-face {
   font-family: "Montserrat-Regular";
   src: url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
   font-family: var(--font-family-bold);
   src: url("./fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
   font-family: "Poppins-Regular";
   src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
   font-family: "Poppins-Bold";
   src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}

/*.App {
   display: flex;
   flex-direction: column;
   min-height: calc(100vh);
}*/

/* custom scroll bar */
::-webkit-scrollbar {
   width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: var(--purple-light);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: var(--purple-dark);
}

.ant-layout {
   background-color: #fff;
}

.ant-message-notice-content {
   font-size: 14px;
   font-weight: 600;
   color: #666;
   padding: 50px 50px !important;
}

.select-place-map .ant-input {
   font-family: var(--font-family-regular);
   font-size: 13px;
   font-weight: 700;
   color: #666 !important;
   padding: 14px 14px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

/** header - begin **/
.ant-layout-header {
   line-height: 59px !important;
}

/** menu - begin **/

.ant-menu,
.ant-menu-item {
   font-weight: 700;
   font-family: var(--font-family-bold);
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.ant-menu .ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu-dark .ant-menu-item,
.ant-menu.ant-menu-dark .ant-menu-sub {
   color: #fff !important;
}

.ant-menu-dark .ant-menu-item:hover {
   background-color: transparent !important;
}

.ant-menu-item-icon {
   font-size: 17px !important;
   position: relative !important;
   top: 4px !important;
   margin-right: 7px !important;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
   color: #333 !important;
}

/** menu - end **/

.ant-popover-message-title {
   font-size: 14px;
   font-weight: 600;
   color: #666 !important;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

/** input - begin **/

.ant-input {
   font-family: var(--font-family-regular);
   font-size: 13px;
   font-weight: 600;
   color: #666 !important;
   padding: 10px 15px;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.ant-input,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:active {
   box-shadow: 0px 0px !important;
   border: 0px solid #eee !important;
}

.ant-input-number input {
   margin-top: 0px !important;
   font-size: 14px !important;
   font-weight: 600 !important;
}

.anticon svg {
   color: var(--red-light);
}

/** input - end **/

/** ant-select - begin **/

.ant-select-selector {
   border-radius: 17px !important;
   font-family: var(--font-family-regular);
   font-size: 13px;
   font-weight: 600;
   color: #666 !important;
   /*padding: 2px 15px !important;
   height: 34px !important;*/
}

.ant-select-item-option-content {
   color: #333 !important;
}

/** ant-select - end **/

/** radio - begin **/

.ant-radio-group {
   margin-top: 15px;
}

.ant-radio-button-wrapper {
   height: 38px;
   font-size: 13px;
   font-weight: 600;
   padding: 3px 25px;
}

/** radio - end **/

/** steps - begin **/

.ant-steps-item-finish .ant-steps-icon-dot {
   background-color: var(--purple-light) !important;
}

.ant-steps-item-process .ant-steps-icon-dot {
   margin-left: 1px;
   background-color: var(--purple-dark) !important;
}

/*.ant-steps-icon-dot::after {
  background-color: transparent !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: var(--purple-dark);
}

.ant-steps-item-finish .ant-steps-item-icon svg {
  fill: var(--purple-dark) !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--purple-dark) !important;
  border-color: var(--purple-dark);
}*/

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
   background-color: #8393ca;
}

/** steps - end **/

/** button - begin **/

.ant-btn-primary {
   background: var(--primary-button) !important;
   border-color: var(--primary-button) !important;
}

.ant-btn-primary:hover {
   color: #fff;
   background: var(--primary-hover-button) !important;
}

.ant-btn-primary:active {
   color: #fff;
   background: var(--primary-active-button) !important;
}

.ant-btn-link {
   background: var(--secondary-button) !important;
   border-color: var(--secondary-button) !important;
}

.ant-btn-link:hover {
   color: #fff;
   background: var(--secondary-hover-button) !important;
}

.ant-btn-link:active {
   color: #fff;
   background: var(--secondary-active-button) !important;
}

/** button - end **/

/** checkbox - begin **/

.ant-checkbox-wrapper {
   display: flex !important;
}

.ant-checkbox-inner {
   margin-top: 3px;
}

.ant-checkbox-inner:hover,
.ant-checkbox-input:hover {
   border: 0px solid #fff !important;
}

/** checkbox - end **/

/** ant upload - begin **/

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
   border-color: var(--red-light) !important;
}

.ant-upload-picture-card-wrapper {
   width: 100px;
   margin-right: 10px;
}

.ant-upload-list-item-actions {
   display: flex;
   width: 100%;
   justify-content: center;
}

/** ant upload - end **/

/** ant picker - begin **/

.ant-picker {
   width: 100%;
   border-color: var(--border-color);
}

.ant-picker-input {
   display: flex;
   justify-items: center;
   align-items: center;
}

.ant-picker input {
   font-size: 14px;
   font-weight: 600;
   /*padding: 0px 5px;*/
   padding: 8px 15px 8px 5px !important;
   display: flex;
   justify-items: center;
   align-items: center;
   line-height: 30px;
   margin-top: 0px !important;
}

.ant-picker:hover {
   border: 1px solid var(--tint-color) !important;
}

.ant-picker:focus-within {
   box-shadow: 0 0 0 2px var(--focus-color) !important;
}

/** ant picker - end **/

/** error - begin **/

.error .fa {
   margin-left: 2px;
}

/** error - end **/

.anticon-search {
   color: var(--primary);
}

/** time selection - begin **/

.css-nakgy8-TimeKeeper {
   box-shadow: 0px 0px #fff !important;
}

.css-1aty463-TopBar,
.css-1nkra40-TopBar {
   color: var(--purple-regular) !important;
}

.css-16j6ljo-ClockHand {
   fill: var(--purple-super-light) !important;
}

.react-timekeeper__clock-wrapper {
   border: 1px solid #eee;
}

.css-bzmfeg-Meridiems,
.css-wufnqa-Meridiems {
   font-family: var(--font-family-regular) !important;
   font-size: var(--font-size-regular) !important;
}

.css-wufnqa-Meridiems {
   background-color: var(--purple-super-light) !important;
}

.css-ue2et3-DoneButton {
   background-color: var(--primary-button) !important;
   font-family: var(--font-family-bold) !important;
   font-size: var(--font-size-regular) !important;
   color: var(--font-color-white) !important;
   margin-top: 5px;
   box-shadow: 0px 0px #fff !important;
   border: 1px solid #fff;
}

.react-timekeeper__clock-hand {
   font-family: var(--font-family-bold) !important;
   font-size: var(--font-size-bold) !important;
   color: var(--font-color-white) !important;
}

/** time selection - begin **/
