.capture-photo .image {
   margin-bottom: 6px;
}

.capture-photo .image-default {
   text-align: center;
   border: 1px dashed #aaa;
   background-color: #fcfcfc;
   margin-bottom: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
}
