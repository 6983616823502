.select-place-map {
   width: 100%;
   height: calc(100vh - 160px);
   position: absolute;
   z-index: 9;
   top: 158px;
   display: flex;
   background: #fff;
}

.select-place-map .container {
   width: var(--main-width);
   top: 0px;
   height: calc(100vh - 265px);
   background-color: #bbb;
   align-items: center;
   justify-content: center;
   margin-left: auto;
   margin-right: auto;
   position: relative;
}

.select-place-map .top-panel {
   position: absolute;
   z-index: 9;
   top: 70px;
   width: 500px;
   margin-left: 250px;
   margin-right: 250px;
   border-radius: 5px;
}

.select-place-map .address {
   padding: 10px;
   margin-bottom: 5px;
   background-color: #fff;
}

.select-place-map .bottom-panel {
   margin-top: 10px;
   bottom: 20px;
   padding: 0px 10px;
   width: 100%;
}

.select-place-map .search-panel {
   width: 500px;
   justify-content: center;
   align-items: center;
   background-color: #fff;
   border-radius: 5px;
}

.select-place-map .search-text {
   margin: 5px 0px 10px 0px;
   padding-left: 5px;
   flex: 1;
   background-color: #fff;
}

.select-place-map .clear-btn {
   padding: 5px;
   margin-right: 5px;
   margin-top: 1px;
   border-radius: 2px;
   background-color: #fff;
}

.select-place-map .image {
   height: 20px;
   width: 20px;
   object-fit: contain;
   align-items: center;
}

.select-place-map .places {
   align-self: stretch;
   margin: 10px 0px;
   padding: 0px 10px;
   border-radius: 5px;
   height: calc(100vh - 180px);
}

.select-place-map .search-result {
   height: calc(100vh - 360px);
   overflow: auto;
}

@media (max-width: 1000px) {
   .select-place-map {
      height: calc(100vh);
      top: 0px;
   }

   .select-place-map .container {
      width: 100%;
      top: 0px;
      height: calc(100vh - 105px);
      border: 0px;
   }

   .select-place-map .top-panel {
      top: 80px;
      width: calc(100vw - 40px);
      margin-left: 20px;
      margin-right: 20px;
      border-radius: 5px;
   }

   .select-place-map .search-panel {
      width: calc(100%);
   }

   .select-place-map .search-result {
      height: calc(100vh - 180px);
   }
}
