.images-gallery {
   display: flex;
   flex-direction: column;
   align-content: flex-start;
   padding: 5px 5px;
}

.images-gallery-heading {
   display: flex;
   flex-direction: row;
   padding-bottom: 5px;
}

.images-gallery-content {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.images-gallery-nav-prev {
   position: absolute;
   z-index: 99;
   left: 5px;
   border: 1px solid #fff;
}

.images-gallery-nav-next {
   position: absolute;
   z-index: 99;
   left: 570px;
   border: 1px solid #fff;
}

.images-gallery-carousel {
   width: 600px;
   height: 600px;
}

.images-gallery-item {
   width: 600px;
   height: 600px;
}

.images-gallery-image {
   object-fit: contain;
   width: 600px;
   height: 600px;
}

@media (max-width: 1000px) {
   .images-gallery-image {
      width: 600px;
      height: 600px;
      max-width: calc(100vw - 40px);
      max-height: calc(100vw - 40px);
   }

   .images-gallery-nav-next {
      left: calc(100vw - 70px);
   }

   .images-gallery-carousel {
      width: 600px;
      height: 600px;
      max-width: calc(100vw - 40px);
      max-height: calc(100vw - 40px);
   }
}

@media (min-width: 500px) {
   .images-gallery-nav-next {
      left: calc(570px);
   }
}
