.regular-border {
   border-width: 1px;
   border-color: var(--border-color);
   border-style: solid;
}

.regular-form-border {
   border-width: 1px;
   border-color: var(--border-form-color);
   border-style: solid;
}

.regular-dash-border {
   border-width: 1px;
   border-color: var(--border-color);
   border-style: dashed;
}

.regular-bottom-side-border {
   border-width: 1px;
   border-top-color: #fff;
   border-left-color: var(--border-color);
   border-right-color: var(--border-color);
   border-bottom-color: var(--border-color);
   border-style: solid;
}

.regular-side-border {
   border-width: 1px;
   border-top-color: #fff;
   border-left-color: var(--border-color);
   border-right-color: var(--border-color);
   border-bottom-color: #fff;
   border-style: solid;
}

.regular-top-border {
   border-width: 1px;
   border-top-color: var(--border-color);
   border-left-color: #fff;
   border-right-color: #fff;
   border-bottom-color: #fff;
   border-style: solid;
}

.regular-bottom-border {
   border-width: 1px;
   border-top-color: #fff;
   border-left-color: #fff;
   border-right-color: #fff;
   border-bottom-color: var(--border-color);
   border-style: solid;
}

.dark-border {
   border-width: 1px;
   border-color: #d9d9d9;
   border-style: solid;
}

.dark-border-bottom-side {
   border-width: 1px;
   border-top-color: #fff;
   border-left-color: #d9d9d9;
   border-right-color: #d9d9d9;
   border-bottom-color: #d9d9d9;
   border-style: solid;
}

.regular-shadow {
   text-shadow: 2px 2px 5px #000;
}

.red-shadow {
   text-shadow: 2px 2px 5px #d5392c;
}

.padding-list {
   padding: 10px 15px 20px 10px;
}

.padding-content {
   padding: 10px 20px 20px 20px;
}

.padding-form {
   padding: 10px 25px 20px 25px;
}

.padding-bottom-content {
   padding: 10px 20px 20px 20px;
}

.padding-bottom-form {
   padding: 10px 25px 20px 25px;
}
