.map-container {
   width: 200px;
   display: flex;
}

.map-tooltip {
   background-color: #ff862d;
   color: #fff;
   padding: 5px 7px;
   display: flex;
   position: relative;
   font-size: 12px;
   line-height: 15px;
}

.map-filler {
   flex: 1;
}
