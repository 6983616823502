.select-place {
   width: 100%;
   height: 100%;
   top: 0px;
   left: 0px;
   margin-left: auto;
   margin-right: auto;
   z-index: 1;
   position: absolute;
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   background: rgb(0, 0, 0, 0.1);
}

.select-place-container {
   display: flex;
   flex-direction: column;
   height: 600px;
   width: 500px;
   align-items: stretch;
   justify-content: flex-start;
   border-radius: 3px;
   background-color: #fff;
   margin: auto;
}

.select-place-search {
   margin-bottom: 5px;
   -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
   -o-animation: fadein 2s; /* Opera < 12.1 */
   animation: fadein 2s;
}

.select-place-search-content {
   padding: 10px 0px;
   height: calc(100vh - 60px);
   display: flex;
   flex-direction: column;
   background-color: #fff;
}

.select-place-search-content .search {
   padding: 0px 10px;
}

.select-place-search-content .buttons {
   padding: 5px 10px;
   display: flex;
   justify-content: flex-end;
}

.select-place input:hover,
.select-place input:active {
   border: 0px solid #fff !important;
}

.select-place .origin-destination {
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: space-between;
   padding: 5px 15px;
}

.select-place .origin-destination-select-info {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   padding: 0px 15px;
   border-top-left-radius: 10px;
   border-bottom-right-radius: 10px;

}

@media (max-width: 1000px) {
   .select-place-container {
      height: 100vh;
      width: 100%;
   }
}