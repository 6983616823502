.head {
   width: 100% !important;
   background-color: #0294bb !important;
   height: 85px !important;
   position: relative;
   text-align: center;
   display: block !important;
}
.headroom {
   width: 100% !important;
   top: 12%;
   position: relative;
}

.headroom .header {
   width: 100%;
   padding: 0 0 !important;
   margin: auto;
   background-color: #0294bb !important;
   display: block;
}

.header-content {
   display: flex;
   margin: auto;
}

.header-content image {
   float: left;
}

.header-left {
   flex: 1;
   display: flex;
}

.header-left .side-bar {
   display: none !important;
}

.header-left img {
   margin-top: -3px;
   height: 45px;
}

.header-right {
   float: right;
   padding-top: 4px;
}

.menu {
   margin: auto !important;
   float: right;
}

.react-icons {
   margin: 0px 10px 0px 0px;
   border: 0px 10px 0px 0px;
   vertical-align: center;
}

.search-bar-hide {
   display: inline;
}

@media (max-width: 1000px) {
   .head {
      width: 100vw !important;
      margin-bottom: 50px !important;
      height: 75px !important; /* height: 86px !important; */
   }
   .no-search-bar {
      margin-bottom: 0px !important;
   }
   .search-bar-hide {
      display: none;
   }
   .header-left {
      width: 100vw !important;
      display: grid;
      position: relative;
      margin-top: -13px !important;
      padding-top: 13px !important;
   }
   .header-left .side-bar {
      display: block !important;
      grid-column: 1;
      grid-row: 1;
   }
   .header-left .image {
      width: 100vw !important;
      display: block;
      grid-column: 1;
      grid-row: 1;
      padding: 15px 15px !important;
      margin: 0px 0px 0px 0px !important;
   }
   .header-left img {
      margin-top: -40px !important;
      height: 50px;
   }
   .header .menu {
      display: none;
   }
}

@media (min-width: 1000px) {
   .header-content,
   .submenu {
      width: var(--main-width) !important;
   }
   .search-bar-hide {
      display: none;
   }
   .menu {
      width: 100% !important;
   }

   .header-content image {
      float: left;
      width: 50px !important;
   }
}
