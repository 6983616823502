.register-success {
   padding: 0px 0px 0px 0px;
   margin: auto;
   display: flex;
   flex-wrap: wrap;
   overflow: hidden;
   min-height: calc(100vh - 530px) !important;
}

.register-success-left {
   width: 225px;
   padding: 0px 0px;
   overflow: hidden;
}

.register-success-right {
   width: 375px;
   overflow: hidden;
}

.register-success .title {
   font-family: var(--font-family-bold);
   font-size: 30px;
}

.register-success .desc {
   padding: 10px 0px;
   font-weight: 600;
   font-size: 15px;
}
.register-success-form button {
   margin-top: 25px;
   font-size: 14px;
   line-height: 14px;
   font-family: var(--font-family-bold);
   padding: 14px 24px;
   height: 42px;
   display: block;
}

@media (max-width: 1000px) {
   .register-success {
      padding: 30px 20px;
      min-height: calc(100vh - 500px) !important;
   }
   .register-success-left {
      display: none;
   }
}
@media (min-width: 1000px) {
   .register-success {
      width: 625px;
      margin-top: calc((100vh / 2 - 350px)) !important;
   }
}
