.login {
   display: flex;
   margin-left: auto;
   margin-right: auto;
   flex-wrap: wrap;
   overflow: hidden;
   height: var(--height) !important;
   min-height: var(--min-height) !important;
   max-height: calc(100);
   justify-content: center;
   align-items: center;
}

.login .ant-input {
   font-size: 15px;
   border: 1px solid #eee !important;
}

.login-left {
   display: flex;
   width: 300px;
   padding: 0px 0px;
   overflow: hidden;
   margin: auto;
   justify-content: center;
}

.login-right {
   width: 400px;
   overflow: hidden;
   margin: auto;
}

.login .doodle {
   height: 300px;
   width: 300px;
   object-fit: cover;
}

.login .title {
   color: var(--primary);
   font-family: var(--font-family-bold);
   font-size: 24px;
   margin-bottom: 10px;
}

.login .sub-title {
   font-family: var(--font-family-bold);
   font-size: 20px;
}

.login .desc {
   padding: 10px 0px;
   font-weight: 600;
   font-size: 15px;
}

.login .error {
   padding: 10px 0px;
   font-size: 15px;
   font-weight: 600;
   color: #f57;
}

.login-form input {
   display: block;
   margin-top: 15px;
}

.login-form button {
   margin-top: 15px !important;
   font-size: 14px;
   line-height: 14px;
   font-family: var(--font-family-bold);
   padding: 14px 0px;
   height: 42px;
   display: block;
   width: 135px;
}

.login-form .join {
   margin-left: 5px;
   background-color: var(--primary) !important;
   border-color: var(--primary) !important;
}

@media (max-width: 1000px) {
   .login-container {
      height: calc(100vh - 120px) !important;
      overflow: auto !important;
   }
   .login-container-no-nav {
      height: calc(100vh - 60px) !important;
   }
   .login {
      flex-direction: column;
      justify-content: flex-start;
   }

   .login-left {
      padding-top: 20px;
   }

   .login-right {
      flex: 1;
      max-width: calc(100vw - 40px);
   }

   .login .doodle {
      height: 220px;
      width: 220px;
      object-fit: cover;
   }
}
@media (min-width: 1000px) {
   .login {
      width: 725px;
   }

   .login-form {
      margin: auto;
      padding: 0px 0px 0px 0px;
   }
}
